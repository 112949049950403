import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import {
  updateSubmission,
  getQualSubmissionByUID,
} from "../utils/firebaseFunctions";
import DiscoveryStepper from "../components/DiscoveryStepper";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCurrentSession } from "../contexts/SessionContext";

const discoveryCategories = [
  "Overview",
  "Monitoring",
  "APM",
  "Synthetic",
  "Sizing",
];

const defaultSelections = {
  overview: [],
  monitoring: [],
  apm: [],
  synthetic: [],
  sizing: [],
};

const headCells = [
  {
    id: "question",
    align: "left",
    disablePadding: true,
    label: "Select All Questions",
  },
  {
    id: "subcategory",
    align: "left",
    disablePadding: true,
    label: "Subcategory",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all questions",
            }}
            sx={{
              "&.Mui-checked": {
                color: "#ed0080",
              },
              "&.MuiCheckbox-indeterminate": {
                color: "#ed0080",
              },
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, tableHeader } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableHeader} ({numSelected} selected)
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableHeader}
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function DiscoveryPlanning() {
  const navigate = useNavigate();
  const { discoveryQuestions, currentDiscovery, setCurrentDiscovery } =
    useCurrentSession();
  const [allSelections, setAllSelections] = React.useState(defaultSelections);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  //Table Functions

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = discoveryQuestions
        .filter(
          (question) =>
            question.category ===
              discoveryCategories[activeStep].toLowerCase() &&
            question.attributes.include === true
        )
        .map((n) => n.name);
      setAllSelections({
        ...allSelections,
        [discoveryCategories[activeStep].toLowerCase()]: newSelected,
      });
      return;
    }
    setAllSelections({
      ...allSelections,
      [discoveryCategories[activeStep].toLowerCase()]: [],
    });
  };

  const handleClick = (event, name) => {
    const selectedIndex =
      allSelections[discoveryCategories[activeStep].toLowerCase()].indexOf(
        name
      );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        allSelections[discoveryCategories[activeStep].toLowerCase()],
        name
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(
        allSelections[discoveryCategories[activeStep].toLowerCase()].slice(1)
      );
    } else if (
      selectedIndex ===
      allSelections[discoveryCategories[activeStep].toLowerCase()].length - 1
    ) {
      newSelected = newSelected.concat(
        allSelections[discoveryCategories[activeStep].toLowerCase()].slice(
          0,
          -1
        )
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        allSelections[discoveryCategories[activeStep].toLowerCase()].slice(
          0,
          selectedIndex
        ),
        allSelections[discoveryCategories[activeStep].toLowerCase()].slice(
          selectedIndex + 1
        )
      );
    }
    setAllSelections({
      ...allSelections,
      [discoveryCategories[activeStep].toLowerCase()]: newSelected,
    });
  };

  const isSelected = (name) =>
    allSelections[discoveryCategories[activeStep].toLowerCase()].indexOf(
      name
    ) !== -1;

  const onFinish = (selectedQuestions) => {
    let selected_count = 0;
    for (var key in selectedQuestions) {
      selected_count += selectedQuestions[key].length;
    }
    if (selected_count === 0) {
      alert("Please select at least one question.");
      return;
    } else {
      updateSubmission(currentDiscovery.docRef, {
        selected_discovery_questions: selectedQuestions,
        discovery_status: "Questions Selected",
      }).then((response) => {
        if (response.success) {
          getQualSubmissionByUID(response.success).then(
            (updated_submission) => {
              setCurrentDiscovery(updated_submission);
              navigate("/discovery-questions");
            }
          );
        } else {
          console.log("Error updating submission");
        }
      });
    }
  };

  React.useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Container component="main" maxWidth="false" sx={{ mb: 2, width: 1640 }}>
      <Grid container spacing={1}>
        <Grid item xs={1} sm={2}>
          <Typography sx={{ fontSize: "1.15rem", mb: 2 }}>
            Review and select from the following questions which will be used in
            your discovery session.
          </Typography>
        </Grid>
        <Grid item xs={6} sm={9}>
          <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
            <DiscoveryStepper
              steps={discoveryCategories}
              activeStep={setActiveStep}
              onFinish={() => onFinish(allSelections)}
            >
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <EnhancedTableToolbar
                    numSelected={
                      allSelections[
                        discoveryCategories[activeStep].toLowerCase()
                      ].length
                    }
                    tableHeader={discoveryCategories[activeStep]}
                  />
                  {!loading ? (
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                      >
                        <EnhancedTableHead
                          numSelected={
                            allSelections[
                              discoveryCategories[activeStep].toLowerCase()
                            ].length
                          }
                          onSelectAllClick={handleSelectAllClick}
                          rowCount={
                            discoveryQuestions.filter(
                              (question) =>
                                question.category ===
                                  discoveryCategories[
                                    activeStep
                                  ].toLowerCase() &&
                                question.attributes.include === true
                            ).length
                          }
                        />
                        <TableBody>
                          {discoveryQuestions
                            .filter(
                              (question) =>
                                question.category ===
                                  discoveryCategories[
                                    activeStep
                                  ].toLowerCase() &&
                                question.attributes.include === true
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.name);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    handleClick(event, row.name)
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.name}
                                  selected={isItemSelected}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#ed0080",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    {row.question}
                                  </TableCell>
                                  <TableCell>
                                    {row.subcategory
                                      .replace(/_/g, " ")
                                      .split(" ")
                                      .map((word) => {
                                        if (
                                          word === "apm" ||
                                          word === "rum" ||
                                          word === "l4o"
                                        ) {
                                          return word.toUpperCase();
                                        } else {
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                          );
                                        }
                                      })
                                      .join(" ")}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Skeleton>
                      <TableContainer />
                    </Skeleton>
                  )}
                </Paper>
              </Box>
            </DiscoveryStepper>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
}
