import React, { useState } from "react";
import { Card } from "@mui/material";

export default function StyledCard({ children }) {
  const [shadow, setShadow] = useState(1);

  const onMouseOver = () => setShadow(5);

  const onMouseOut = () => setShadow(1);

  return (
    <Card
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      elevation={shadow}
      sx={{ height: "100%", display: "flex" }}
    >
      {children}
    </Card>
  );
}
