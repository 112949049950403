import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Icon,
  List,
  ListItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";

import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import {
  getQualSubmissionByUID,
  updateSubmission,
} from "../utils/firebaseFunctions";
import { HelpOutline } from "@mui/icons-material";
import { useCurrentSession } from "../contexts/SessionContext";
import { calculateQualificationResults } from "./QualificationFunctions";
import LoadingBar from "../components/LoadingBar";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function QualificationNeeds(props) {
  const {
    qualificationNeeds,
    winzoneQualifiers,
    currentQualification,
    setCurrentQualification,
  } = useCurrentSession();
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [checked, setChecked] = React.useState([]);

  const handleBack = () => {
    setFormValues({});
    //props.onBack();
    navigate("/qualification-initiatives");
  };

  const checkChange = (e) => {
    const { name, value } = e.target;
    if (checked.indexOf(name) !== -1) {
      setChecked(checked.filter((checkBox) => checkBox !== name));
      delete formValues[name];
    } else {
      setChecked([...checked, name]);
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    let submissionArray = [];
    e.preventDefault();
    setLoading(true);
    for (var key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        submissionArray.push({ name: key, values: formValues[key] });
      }
    }

    let result = calculateQualificationResults(
      {
        ...currentQualification,
        qualification_needs: submissionArray,
      },
      winzoneQualifiers,
      qualificationNeeds
    );
    let updated_submission = {
      ...currentQualification,
      qualification_needs: submissionArray,
      result: result,
      qualification_status: "Complete",
    };

    updateSubmission(currentQualification.docRef, updated_submission).then(
      (response) => {
        if (response.success) {
          getQualSubmissionByUID(response.success).then(
            (updated_submission) => {
              setCurrentQualification(updated_submission);
              navigate("/qualification-results");
            }
          );
        } else {
          console.log("Error updating submission");
        }
      }
    );
  };

  React.useEffect(() => {
    setLoading(false);
  }, [currentQualification]);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
      {!loading ? (
        <React.Fragment>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={2} sm={3}>
              <Typography sx={{ fontSize: "1.15rem", mb: 2 }}>
                Identify the top three needs or priorities for this opportunity.
                The best way to uncover this information is ask{" "}
                <span className="splunk-pink">the primary stakeholders</span>:
              </Typography>
              <Typography
                paragraph={true}
                sx={{
                  borderLeft: "5px solid rgba(237, 0, 128, .65)",
                  fontSize: "1.5rem",
                  paddingLeft: "1rem",
                  mb: 2,
                }}
              >
                "As it relates to your current monitoring strategy take a moment
                to think about some challenges you've experienced and describe
                your top three pain points."
              </Typography>
              <Typography sx={{ fontSize: "1.15rem", mb: 2 }}>
                Common pain points for each need can be viewed by hovering over
                the{" "}
                <HelpOutline
                  sx={{
                    verticalAlign: "middle",
                    color: "#ed0080",
                    fontSize: "2rem",
                  }}
                />
              </Typography>
              <Typography sx={{ fontSize: "1.15rem", mb: 2 }}>
                Please select up to 3 of the following needs that most closely
                align to the opportunity with{" "}
                {currentQualification.details.account}.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={9}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    {!loading &&
                      qualificationNeeds
                        .filter((n) => n.attributes.include === true)
                        .map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={{ width: "75%" }}>
                              <Typography sx={{ fontSize: "1.15rem" }}>
                                {row.need}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <CustomWidthTooltip
                                title={
                                  <React.Fragment>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Typography align="center">
                                          Common Pain Points:
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <List>
                                          {row.pain_points.map((p) => (
                                            <ListItem
                                              key={p}
                                              divider
                                              sx={{ fontSize: "1rem" }}
                                            >
                                              {p}
                                            </ListItem>
                                          ))}
                                        </List>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Icon sx={{ width: "40px", height: "40px" }}>
                                  <HelpOutline
                                    sx={{
                                      verticalAlign: "middle",
                                      color: "#ed0080",
                                      fontSize: "2rem",
                                    }}
                                  />
                                </Icon>
                              </CustomWidthTooltip>
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ float: "right" }}>
                                <React.Fragment>
                                  <FormControlLabel
                                    key={row.name}
                                    onChange={checkChange}
                                    control={
                                      <Checkbox
                                        key={row.name}
                                        checked={checked.includes(row.name)}
                                        disabled={
                                          !checked.includes(row.name) &&
                                          checked.length > 2
                                        }
                                        value={row.need}
                                        name={row.name}
                                        sx={{
                                          "&.Mui-checked": {
                                            color: "#ed0080",
                                          },
                                        }}
                                      />
                                    }
                                  />
                                </React.Fragment>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit}
            action="qualification-results"
          >
            <React.Fragment>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    onClick={handleBack}
                    variant="outlined"
                    className="pink-button"
                    sx={{
                      mt: 3,
                      color: "#ffffff",
                      borderColor: "#ffffff",
                      "&:hover": {
                        borderColor: "#ed0080",
                        color: "#ed0080",
                      },
                    }}
                  >
                    Back to Value Zones
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    type="submit"
                    className="pink-button"
                    sx={{
                      mt: 3,
                      color: "#ffffff",
                      borderColor: "#ffffff",
                      "&:hover": {
                        borderColor: "#ed0080",
                        color: "#ed0080",
                      },
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </Box>
        </React.Fragment>
      ) : (
        <LoadingBar />
      )}
    </Container>
  );
}
