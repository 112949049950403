import { Divider, Grid, Paper, Typography } from "@mui/material";
import * as React from "react";

export default function DashboardCard(props) {
  const { title, value, cwidth, cheight } = props;
  return (
    <Paper
      elevation={1}
      sx={{
        width: cwidth === undefined ? 300 : cwidth,
        height: cheight === undefined ? 200 : cheight,
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography
            textAlign="left"
            variant="h6"
            sx={{ ml: 2, fontSize: 22 }}
            gutterBottom
          >
            {title}
          </Typography>
          <Divider variant="middle" />
        </Grid>
        <Grid item>
          <Typography
            className="splunk-pink"
            textAlign="center"
            sx={{ fontSize: 60, mt: 3 }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
