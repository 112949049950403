import firebaseApp from "../firebase";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  query,
  runTransaction,
  //Firebase Emulator Config
  connectFirestoreEmulator,
} from "firebase/firestore";
//Emulator Config
import { getAuth, connectAuthEmulator } from "firebase/auth";

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);
const auth = getAuth();

if (process.env.REACT_APP_TARGET.toUpperCase() === "EMULATOR") {
  connectAuthEmulator(
    auth,
    "http://" + process.env.REACT_APP_EMULATOR_HOST + ":9099"
  );
  connectFirestoreEmulator(db, "localhost", "8080");
}

const collectionEnv = process.env.REACT_APP_FIREBASE_COLLECTION;
const submissionsCollection = `${collectionEnv}_submissions/`;

export async function writeSubmissionToFirebase(details) {
  try {
    const docRef = await addDoc(
      collection(
        db,
        `${submissionsCollection}${auth.currentUser.uid}/submissions/`
      ),
      details
    );
    let update = {};
    update["details.docRef"] = docRef.id;
    await updateSubmission(docRef.id, { docRef: docRef.id });
    return {
      success: docRef.id,
    };
  } catch (e) {
    return {
      failure: e,
    };
  }
}

export async function updateSubmission(docRefId, details) {
  const docRef = doc(
    db,
    `${submissionsCollection}${auth.currentUser.uid}/submissions/`,
    docRefId
  );
  //const docSnap = await getDoc(docRef);
  try {
    const update_submission = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.update(
        doc(
          db,
          `${submissionsCollection}${auth.currentUser.uid}/submissions/`,
          docRefId
        ),
        details
      );
      return { success: docRefId };
    });
    return update_submission;
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
}

export async function getStuffFromFirebase(stuffToGet) {
  const q = query(
    collection(db, stuffToGet)
    //`where("attributes.include", "==", true)
  );
  const querySnapshot = await getDocs(q);
  const stuff = [];
  querySnapshot.forEach((doc) => {
    stuff.push(doc.data());
  });
  return stuff;
}

export async function getQualSubmissions() {
  const querySnapshot = await getDocs(
    collection(
      db,
      `${submissionsCollection}${auth.currentUser.uid}/submissions/`
    )
  );
  const stuff = [];

  querySnapshot.forEach((doc) => {
    let submission = doc.data();
    submission["docRef"] = doc.id;
    stuff.push(submission);
  });
  return stuff;
}

export async function getQualSubmissionByUID(docRefId) {
  const docRef = doc(
    db,
    `${submissionsCollection}${auth.currentUser.uid}/submissions/`,
    docRefId
  );
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    throw "Document does not exist!";
  }
  return docSnap.data();
}

export async function checkProfile() {
  const profileCollection = "users/" + auth.currentUser.uid;
  const details = {
    displayName: auth.currentUser.displayName,
    email: auth.currentUser.email,
    firstLogin: true,
    showProfileModal: true,
  };
  const profileDocRef = doc(db, profileCollection);
  const profileDocSnap = await getDoc(profileDocRef);
  if (!profileDocSnap.exists()) {
    try {
      await setDoc(doc(db, profileCollection), details);
      return details;
    } catch (e) {
      return {
        failure: e,
      };
    }
  } else {
    return profileDocSnap.data();
  }
}

export async function updateProfileAfterLogin(details) {
  const docRef = doc(db, "users", auth.currentUser.uid);
  details.firstLogin = false;
  try {
    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.update(doc(db, "users", auth.currentUser.uid), details);
    });
    return { success: true };
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
  return { success: true };
}

export async function updateProfile(details) {
  const docRef = doc(db, "users", auth.currentUser.uid);
  try {
    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.update(doc(db, "users", auth.currentUser.uid), details);
      return { success: true };
    });
  } catch (e) {
    console.log("Transaction failed: ", e);
  }

  return { success: true };
}

export async function getProfileByUid() {
  const docRef = doc(db, "users", auth.currentUser.uid);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    throw "Document does not exist!";
  }
  return docSnap.data();
}

export async function getGlobalStatistics() {
  const docRef = doc(db, `${submissionsCollection}`, "--summary--");
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    throw "Document does not exist!";
  }
  return docSnap.data();
}
