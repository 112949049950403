export function calculateDiscoveryResults(submission, discovery_questions) {
  let discovery_results = [];

  Object.keys(submission.discovery_responses).map((r) => {
    const response = submission.discovery_responses[r];
    const result = discovery_questions.find((q) => q.name === r);

    const details = {
      name: result.name,
      question: result.question,
      notes: response.notes,
      attributes: result.attributes,
      category: result.category,
    };

    if (typeof response.selection === "string" && response.selection !== "") {
      let responses = [];
      console.log(response.selection.toString().toLowerCase());
      if (result.category === "sizing") {
        responses.push({
          category: result.category,
          response: result.response.sizing_description,
        });
      } else {
        Object.keys(
          result.response[response.selection.toString().toLowerCase()]
        ).map((d) => {
          responses.push({
            category: d,
            response:
              result.response[response.selection.toString().toLowerCase()][d],
          });
        });
      }
      details.responses = responses;
      details.answer = response.selection;
    } else {
      let answers = [];
      let responses = [];
      Object.keys(response.selection)
        .filter((s) => response.selection[s] === true)
        .map((t) => {
          answers.push(t);

          if (result.category !== "sizing") {
            Object.keys(result.response[t]).map((d) => {
              responses.push({ category: d, response: result.response[t][d] });
            });
          }
        });
      if (result.category === "sizing") {
        responses.push({
          category: result.category,
          response: result.response.sizing_description,
        });
      }
      details.responses = responses;
      details.answer = answers;
    }
    discovery_results.push(details);
  });
  return discovery_results;
}
