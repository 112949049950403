import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function QualificationResultComponent(props) {
  const [expanded, setExpanded] = React.useState("panel1");
  const accordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      {!props.loading &&
        props.valueZone
          .filter((n) => n.title === props.submission.salesplay.name)
          .map((vz) => (
            <Grid
              key={vz.id}
              container
              spacing={1}
              justifyContent="center"
              direction="column"
            >
              <Grid item xs={12} md={6} container direction="row">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={accordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="splunk-pink" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item width="100%">
                        <Typography
                          variant="h6"
                          justifyContent="left"
                          align="left"
                          fontSize="1.5rem"
                          sx={{ fontWeight: 700 }}
                        >
                          Salesplay: <span className="elevate">{vz.title}</span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="text.secondary">
                          {vz.headline}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item width="100%" sx={{ mb: 3 }}>
                        <Typography>{vz.description}</Typography>
                      </Grid>
                      {props.submission.result.responses.length > 0 &&
                      props.step === "qualification" ? (
                        <Grid item width="100%" sx={{ mb: 2 }}>
                          <Typography
                            variant="h6"
                            justifyContent="left"
                            align="left"
                            fontSize="1.5rem"
                            color="error.light"
                            sx={{ fontWeight: 700 }}
                          >
                            Cautions
                          </Typography>
                          <Typography color="text.secondary">
                            The success of this opportunity may be impacted by
                            the following:
                          </Typography>
                          <List>
                            {props.submission.result.responses.map(
                              (caution, i) => (
                                <ListItem key={i}>{caution}</ListItem>
                              )
                            )}
                          </List>
                        </Grid>
                      ) : null}
                      <Grid item width="100%">
                        <Typography
                          variant="h6"
                          justifyContent="left"
                          align="left"
                          fontSize="1.5rem"
                          sx={{ fontWeight: 700 }}
                        >
                          Next Steps
                        </Typography>
                        <Typography color="text.secondary">
                          Review the following {vz.title} assets:
                        </Typography>
                        <List>
                          {vz.assets.map((asset) => (
                            <ListItem key={asset.id}>
                              <Link target="_blank" href={asset.link}>
                                {asset.name}
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Review the following{" "}
                          <Link
                            target="_blank"
                            href={
                              "https://bossofopsando11y.com/workshops?filter=" +
                              props.submission.salesplay.id
                            }
                          >
                            workshops
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          ))}
    </React.Fragment>
  );
}
