import React, { useContext, useState, useEffect } from "react";
import {
  getProfileByUid,
  getStuffFromFirebase,
} from "../utils/firebaseFunctions";
import { useAuth } from "./AuthContext";

const SessionContext = React.createContext();

export function useCurrentSession() {
  return useContext(SessionContext);
}

export function SessionProvider({ children }) {
  const { currentUser } = useAuth();
  const [winzoneQualifiers, setWinzoneQualifiers] = useState();
  const [valueZones, setValueZones] = useState();
  const [qualificationNeeds, setQualificationNeeds] = useState();
  const [usecaseQualificationQuestions, setUsecaseQualificationQuestions] =
    useState();
  const [discoveryQuestions, setDiscoveryQuestions] = useState();
  const [currentQualification, setCurrentQualification] = useState(
    sessionStorage.getItem("current_qualification")
  );
  const [currentDiscovery, setCurrentDiscovery] = useState(
    sessionStorage.getItem("current_discovery")
  );
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("rendered session provider", new Date().getTime());
    const fetchData = async () => {
      try {
        const winzone_qualifiers = await getStuffFromFirebase(
          "questions/category/winzone_qualifiers"
        );
        setWinzoneQualifiers(winzone_qualifiers);
      } catch (err) {
        console.error(err);
      }

      try {
        const value_zones = await getStuffFromFirebase(
          "questions/category/value_zones"
        );
        value_zones.sort((a, b) =>
          a.attributes.order_id > b.attributes.order_id ? 1 : -1
        );
        setValueZones(value_zones);
      } catch (err) {
        console.error(err);
      }

      try {
        const qualification_needs = await getStuffFromFirebase(
          "questions/category/qualification_needs"
        );
        qualification_needs.sort((a, b) =>
          a.attributes.order_id > b.attributes.order_id ? 1 : -1
        );
        setQualificationNeeds(qualification_needs);
      } catch (err) {
        console.error(err);
      }

      try {
        const usecase_qualification_questions = await getStuffFromFirebase(
          "questions/category/usecase_qualification_questions"
        );
        setUsecaseQualificationQuestions(usecase_qualification_questions);
      } catch (err) {
        console.error(err);
      }

      try {
        const discovery_questions = await getStuffFromFirebase(
          "questions/category/discovery_questions"
        );
        setDiscoveryQuestions(discovery_questions);
      } catch (err) {
        console.error(err);
      }

      try {
        const userProfile = await getProfileByUid(currentUser.uid);
        setProfile(userProfile);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    fetchData();
  }, [currentUser.uid]);

  useEffect(() => {
    console.log("current qualification changed", new Date().getTime());
    if (currentQualification) {
      sessionStorage.setItem(
        "current_qualification",
        JSON.stringify(currentQualification)
      );
    }
  }, [currentQualification]);

  useEffect(() => {
    console.log("current discovery changed", new Date().getTime());
    if (currentDiscovery) {
      sessionStorage.setItem(
        "current_discovery",
        JSON.stringify(currentDiscovery)
      );
    }
  }, [currentDiscovery]);

  const value = {
    winzoneQualifiers,
    valueZones,
    qualificationNeeds,
    usecaseQualificationQuestions,
    discoveryQuestions,
    loading,
    currentQualification,
    setCurrentQualification,
    currentDiscovery,
    setCurrentDiscovery,
    profile,
    setProfile,
  };

  return (
    <SessionContext.Provider value={value}>
      {!loading && children}
    </SessionContext.Provider>
  );
}
