import * as React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
  Button,
  Container,
  ListItemIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SpeakerNotesRoundedIcon from "@mui/icons-material/SpeakerNotesRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopoverButton from "../components/PopoverButton";
import QualificationResultComponent from "../components/QualificationResultComponent";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { ChevronRightRounded, ArrowRight } from "@mui/icons-material";
import { useCurrentSession } from "../contexts/SessionContext";
import { calculateDiscoveryResults } from "./DiscoveryFunctions";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
    "& .MuiDataGrid-withBorder": {
      borderRight: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell": {
      wordWrap: "break-word !important",
    },
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const discovery_categories = [
  {
    name: "additional_information",
    category: "Additional Information",
    order: 0,
    description: "Additional information about the opportunity",
  },
  {
    name: "demo_detail",
    category: "Demo Details",
    order: 3,
    description:
      "Details about the opportunity that will assist in providing a more curated demo",
  },
  {
    name: "opportunities",
    category: "Keys to Success",
    order: 2,
    description: "Information that will help you win the opportunity",
  },
  {
    name: "cautions",
    category: "Cautions",
    order: 1,
    description: "Information that may impact the success of the opportunity",
  },
  {
    name: "competition",
    category: "Competition",
    order: 4,
    description: "Comptetive details about the customer environment",
  },
  {
    name: "sizing",
    category: "Sizing",
    order: 5,
    description: "Information about the sizing requirements of the opportunity",
  },
  {
    name: "all_responses",
    category: "All Responses",
    order: 6,
    description: "All discovery responses",
  },
];

function sortCategories(responses) {
  const response_set = new Set(
    responses
      .map((d) => d.responses)
      .flat()
      .map((d) => d.category)
  );
  let categories = [];
  discovery_categories
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((c) => {
      if (response_set.has(c.name)) {
        categories.push(c.name);
      }
    });
  categories.push("all_responses");
  return categories;
}

function listResponses(section, responseDetails, qualification, classes) {
  switch (section) {
    case "cautions":
      return (
        <List>
          {qualification.result.responses
            .concat(
              responseDetails
                .map((d) => d.responses)
                .flat()
                .filter((d) => d.category === section)
                .map((d) => d.response)
            )
            .map((d, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText sx={{ ml: -3 }}>{d}</ListItemText>
              </ListItem>
            ))}
        </List>
      );
    case "demo_detail":
      return (
        <Box sx={{ height: 630, width: "100%" }}>
          <DataGrid
            className={classes.root}
            rows={responseDetails
              .map((f) =>
                f.responses.map((d) => ({
                  category: d.category,
                  response: d.response,
                  demo_category: f.attributes.demo_category,
                }))
              )
              .map((d) => d.filter((f) => f.category === section))
              .flat()
              .sort((a, b) => (a.demo_category > b.demo_category ? 1 : -1))
              .map((d, i) => ({
                id: i,
                category: d.demo_category,
                response: d.response,
              }))}
            columns={[
              {
                field: "category",
                headerName: "Category",
                width: 175,
              },
              {
                field: "response",
                headerName: "Description",
                renderCell: renderQuestion,
                width: 900,
              },
            ]}
            showColumnRightBorder={true}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableColumnMenu
            disableSelectionOnClick
            rowHeight={104}
          />
        </Box>
      );
    case "sizing":
      return (
        <Box sx={{ height: 371, width: "100%" }}>
          <DataGrid
            className={classes.root}
            rows={responseDetails
              .filter(
                (i) =>
                  i.responses.filter((j) => j.category.includes(section))
                    .length > 0
              )
              .map((d) =>
                d.responses.map((r) => ({
                  id: d.name,
                  category: r.response,
                  response:
                    typeof d.answer === "object"
                      ? d.answer.join(", ")
                      : d.answer,
                }))
              )
              .flat()}
            columns={[
              {
                field: "category",
                headerName: "Description",
                width: 687.5,
              },
              {
                field: "response",
                headerName: "Response",
                width: 687.5,
              },
            ]}
            showColumnRightBorder={true}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableColumnMenu
            disableSelectionOnClick
          />
        </Box>
      );
    case "all_responses":
      return (
        <Box sx={{ height: 630, width: "100%" }}>
          <DataGrid
            className={classes.root}
            rows={responseDetails.map((d) => ({
              id: d.name,
              question: d.question,
              response:
                typeof d.answer === "object" ? d.answer.join(", ") : d.answer,
              notes: d.notes,
            }))}
            columns={[
              {
                field: "question",
                headerName: "Discovery Question",
                renderCell: renderQuestion,
                width: 600,
              },
              {
                field: "response",
                headerName: "Response",
                width: 200,
              },
              {
                field: "notes",
                headerName: "Additional Notes",
                renderCell: additionalNotes,
                width: 200,
                align: "center",
                headerAlign: "center",
              },
            ]}
            showColumnRightBorder={true}
            pageSize={5}
            rowsPerPageOptions={[10]}
            checkboxSelection={false}
            disableColumnMenu
            disableSelectionOnClick
            rowHeight={104}
          />
        </Box>
      );
    default:
      return (
        <List>
          {responseDetails
            .map((d) => d.responses)
            .flat()
            .filter((d) => d.category === section)
            .map((d, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
                <ListItemText sx={{ ml: -3 }}>{d.response}</ListItemText>
              </ListItem>
            ))}
        </List>
      );
  }
}

const renderQuestion = (props) => {
  const { value } = props;
  return (
    <Grid container style={{ display: "flex", alignItems: "center" }}>
      <Grid item sx={{ whiteSpace: "normal" }}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const additionalNotes = (props) => {
  const { value } = props;

  if (value) {
    return (
      <CustomWidthTooltip
        title={
          <React.Fragment>
            <Typography align="center" sx={{ fontWeight: 700 }}>
              Additional Notes
            </Typography>
            <Typography align="center">{value}</Typography>
          </React.Fragment>
        }
        placement="left"
        arrow
      >
        <Icon sx={{ width: "40px", height: "40px" }}>
          <SpeakerNotesRoundedIcon
            sx={{
              verticalAlign: "middle",
              color: "#ed0080",
              fontSize: "2rem",
            }}
          />
        </Icon>
      </CustomWidthTooltip>
    );
  } else {
    return null;
  }
};

additionalNotes.propTypes = {
  value: PropTypes.string,
};

export default function DiscoveryResults() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [responseDetails, setResponseDetails] = React.useState({});
  const [sections, setSections] = React.useState([]);
  const [responseTable, setResponseTable] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const {
    currentDiscovery,
    valueZones,
    discoveryQuestions,
    usecaseQualificationQuestions,
  } = useCurrentSession();

  React.useEffect(() => {
    const response_details = calculateDiscoveryResults(
      currentDiscovery,
      discoveryQuestions
    );
    const sorted_sections = sortCategories(response_details);

    setSections(sorted_sections);
    setResponseDetails(response_details);
    setLoading(false);
  }, [currentDiscovery, discoveryQuestions]);

  React.useEffect(() => {
    const createTable = (qualification, questions) => {
      let responses = [];
      Object.entries(qualification.qualification_responses).map((response) => {
        responses.push({
          question: questions.find((q) => q.name === response[0]).question,
          response: response[1].response,
          persona: questions.find((q) => q.name === response[0]).target_persona,
        });
      });
      return responses;
    };

    if (currentDiscovery.qualification_responses) {
      setResponseTable(
        createTable(currentDiscovery, usecaseQualificationQuestions)
      );
    }
    setLoading(false);
  }, [currentDiscovery, usecaseQualificationQuestions]);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
      {!loading ? (
        <React.Fragment>
          <Typography variant="h4" gutterBottom>
            Discovery Results for {currentDiscovery.details.account}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
            You've identified the following needs for the opportunity at{" "}
            {currentDiscovery.details.account}:
          </Typography>

          {currentDiscovery.usecases.map((usecase, i) => {
            return (
              <Grid container spacing={1} justifyContent="left" key={i}>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  sx={{ width: 60 }}
                >
                  <ChevronRightRounded
                    color="success"
                    fontSize="large"
                    sx={{ mt: -2 }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  maxWidth="md"
                >
                  <Typography paragraph textAlign="left" fontSize="1.2rem">
                    {valueZones
                      .find((vz) => vz.id === currentDiscovery.salesplay.id)
                      .usecases.filter((vz) => vz.id === usecase)
                      .map((vz) => vz.usecase)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </React.Fragment>
      ) : null}

      {!loading ? (
        <React.Fragment>
          <QualificationResultComponent
            submission={currentDiscovery}
            valueZone={valueZones}
            loading={loading}
          />
        </React.Fragment>
      ) : null}
      <Grid
        key="responses"
        container
        spacing={1}
        justifyContent="center"
        direction="column"
        sx={{ mt: 0.1 }}
      >
        {!loading &&
          sections
            //.sort((a, b) => (a.order > b.order ? 1 : -1))
            .filter((category) => !category.includes("additional_information"))
            .map((section) => (
              <Grid item>
                <Accordion key={section}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="splunk-pink" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item width="100%">
                        <Typography
                          variant="h6"
                          justifyContent="left"
                          align="left"
                          fontSize="1.2rem"
                          sx={{ fontWeight: 700 }}
                          color={
                            section.includes("cautions")
                              ? "error.main"
                              : "text.primary"
                          }
                        >
                          {
                            discovery_categories.find(
                              (category) => category.name === section
                            ).category
                          }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="text.secondary">
                          {
                            discovery_categories.find(
                              (category) => category.name === section
                            ).description
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {listResponses(
                      section,
                      responseDetails,
                      currentDiscovery,
                      classes
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}

        {!loading &&
          currentDiscovery.personas.map((persona) => (
            <Grid item>
              <Accordion key={persona}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="splunk-pink" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container>
                    <Grid item width="100%">
                      <Typography
                        variant="h6"
                        justifyContent="left"
                        align="left"
                        fontSize="1.2rem"
                        sx={{ fontWeight: 700 }}
                      >
                        Qualification Responses
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="text.secondary">{persona}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="47.5%">
                            <Typography>Question</Typography>
                          </TableCell>
                          <TableCell width="5%" />
                          <TableCell width="47.5%">
                            <Typography>Response</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {responseTable
                          .filter((r) => r.persona === persona)
                          .map((response) => {
                            return (
                              <TableRow key={response[0]}>
                                <TableCell>
                                  <Typography>{response.question}</Typography>
                                </TableCell>
                                <TableCell />
                                <TableCell>
                                  <Typography>{response.response}</Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="right"
        spacing={2}
        sx={{ mb: 5 }}
      >
        <Grid item>
          <PopoverButton text={"Share"} />
        </Grid>
        <Grid item>
          <Button
            className="pink-button"
            variant="outlined"
            onClick={() => navigate("/profile")}
            sx={{
              mt: 3,
            }}
          >
            Back to Profile
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
