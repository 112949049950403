import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useAuth } from "../contexts/AuthContext";
import DashboardCard from "./DashboardCard";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import {
  getProfileByUid,
  getQualSubmissions,
} from "../utils/firebaseFunctions";
import HomepageModal from "./HomepageModal";

ChartJS.register(ArcElement, Tooltip);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Profile(props) {
  const { currentUser } = useAuth();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [submissions, setSubmissions] = React.useState();
  const [profile, setProfile] = React.useState();
  const [fetchingData, setFetchingData] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [pieData, setPieData] = React.useState();

  React.useEffect(() => {
    function data() {
      const vzs = submissions
        .filter((s) => s.qualification_status === "Complete")
        .map((s) => s.salesplay.name);
      const counts = {};

      for (const num of vzs) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }
      console.log(counts);
      setPieData({
        labels: Object.keys(counts),
        datasets: [
          {
            data: Object.values(counts),
            backgroundColor: ["#ed0080", "#ca03fc", "#f16212"],
            borderWidth: 0,
          },
        ],
      });
      setLoading(false);
    }
    if (!fetchingData) {
      data();
    }
  }, [submissions, fetchingData]);

  React.useEffect(() => {
    const fetchData = async () => {
      // Get qualification submissions
      try {
        const data = await getQualSubmissions();
        data.sort((a, b) =>
          a.details.created_on < b.details.created_on ? 1 : -1
        );
        setSubmissions(data);
      } catch (err) {
        console.error(err);
      }
      // Get profile data
      try {
        const userProfile = await getProfileByUid(currentUser.uid);
        setProfile(userProfile);
      } catch (e) {
        console.log(e);
      }
      setFetchingData(false);
    };

    fetchData();
  }, [currentUser]);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 20 }}>
      {!loading && profile.showProfileModal ? <HomepageModal /> : null}
      {!loading && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              About you
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2} justifyContent="center">
              <Card style={{ width: "48.4vw" }}>
                <Box>
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={profile.displayName}
                        src={currentUser.photoURL}
                        sx={{ width: 56, height: 56 }}
                      />
                    }
                    action={
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        sx={{ color: "#ed0080", mt: 1 }}
                      >
                        <ExpandMoreIcon fontSize="large" />
                      </ExpandMore>
                    }
                    title={profile.displayName}
                    titleTypographyProps={{ variant: "h5" }}
                    subheader={profile.role}
                  />
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Divider />
                      <Grid container spacing={2} sx={{ mt: 2, ml: 4 }}>
                        <Grid item xs={12} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                          >
                            <Grid item xs>
                              <Typography
                                gutterBottom
                                component="div"
                                variant="body2"
                                color="text.secondary"
                              >
                                Full Name
                              </Typography>
                              <Typography variant="body3" gutterBottom>
                                {currentUser.displayName}
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                gutterBottom
                                component="div"
                                variant="body2"
                                color="text.secondary"
                              >
                                Display Name
                              </Typography>
                              <Typography variant="body3" gutterBottom>
                                {profile.displayName}
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                gutterBottom
                                component="div"
                                variant="body2"
                                color="text.secondary"
                              >
                                Role
                              </Typography>
                              <Typography variant="body3" gutterBottom>
                                {profile.role}
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography
                                gutterBottom
                                component="div"
                                variant="body2"
                                color="text.secondary"
                              >
                                Theater/Region
                              </Typography>
                              <Typography variant="body3" gutterBottom>
                                {profile.theater}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Collapse>
                </Box>
              </Card>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="h6">Submission Details</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <DashboardCard
                  title="Accounts"
                  value={
                    new Set(
                      submissions.map(
                        (submission) => submission.details.account
                      )
                    ).size
                  }
                />
              </Grid>
              <Grid item>
                <DashboardCard
                  title="Completed Qualifications"
                  value={
                    submissions.filter(
                      (submission) =>
                        submission.qualification_status === "Complete"
                    ).length
                  }
                />
              </Grid>
              <Grid item>
                <DashboardCard
                  title="Incomplete Qualifications"
                  value={
                    submissions.filter(
                      (submission) =>
                        submission.qualification_status !== "Complete"
                    ).length
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <DashboardCard
                  title="Started Discovery"
                  value={
                    submissions.filter(
                      (submission) =>
                        submission.discovery_status === "Questions Selected"
                    ).length
                  }
                />
              </Grid>
              <Grid item>
                <DashboardCard
                  title="Completed Discovery"
                  value={
                    submissions.filter(
                      (submission) => submission.discovery_status === "Complete"
                    ).length
                  }
                />
              </Grid>
              <Grid item>
                <Paper elevation={1} sx={{ width: 300, height: 200 }}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        textAlign="left"
                        variant="h6"
                        sx={{ ml: 2, fontSize: 22 }}
                        gutterBottom
                      >
                        Sales Plays
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item alignItems="center">
                      <Box
                        style={{
                          height: "140px",
                          width: "140px",
                          margin: "0 auto",
                          marginTop: "6px",
                          overflow: "show",
                        }}
                      >
                        <Pie
                          data={pieData}
                          options={{
                            maintainAspectRatio: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                enabled: false,
                                displayColors: false,
                                external: function (context) {
                                  // Tooltip Element
                                  let tooltipEl =
                                    document.getElementById("chartjs-tooltip");

                                  // Create element on first render
                                  if (!tooltipEl) {
                                    tooltipEl = document.createElement("div");
                                    tooltipEl.id = "chartjs-tooltip";
                                    tooltipEl.innerHTML = "<table></table>";
                                    document.body.appendChild(tooltipEl);
                                  }

                                  // Hide if no tooltip
                                  const tooltipModel = context.tooltip;
                                  if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                  }

                                  // Set caret Position
                                  tooltipEl.classList.remove(
                                    "above",
                                    "below",
                                    "no-transform"
                                  );
                                  if (tooltipModel.yAlign) {
                                    tooltipEl.classList.add(
                                      tooltipModel.yAlign
                                    );
                                  } else {
                                    tooltipEl.classList.add("no-transform");
                                  }

                                  function getBody(bodyItem) {
                                    return bodyItem.lines;
                                  }

                                  // Set Text
                                  if (tooltipModel.body) {
                                    const titleLines = tooltipModel.title || [];
                                    const bodyLines =
                                      tooltipModel.body.map(getBody);

                                    let innerHtml = "<thead>";

                                    titleLines.forEach(function (title, i) {
                                      innerHtml +=
                                        "<tr><th>" +
                                        title +
                                        ": " +
                                        bodyLines[i] +
                                        "</th></tr>";
                                    });
                                    innerHtml += "</thead><tbody>";
                                    innerHtml += "</tbody>";

                                    let tableRoot =
                                      tooltipEl.querySelector("table");
                                    tableRoot.innerHTML = innerHtml;
                                  }

                                  const position =
                                    context.chart.canvas.getBoundingClientRect();

                                  // Display, position, and set styles for font
                                  tooltipEl.style.opacity = 1;
                                  tooltipEl.style.position = "absolute";
                                  tooltipEl.style.left =
                                    position.left +
                                    window.pageXOffset +
                                    tooltipModel.caretX +
                                    "px";
                                  tooltipEl.style.top =
                                    position.top +
                                    window.pageYOffset +
                                    tooltipModel.caretY +
                                    "px";

                                  tooltipEl.style.padding =
                                    tooltipModel.padding +
                                    "px " +
                                    tooltipModel.padding +
                                    "px";
                                  tooltipEl.style.pointerEvents = "none";
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
