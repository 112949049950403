import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Container } from "@mui/material";
import { ChevronRightRounded } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QualificationResultComponent from "../components/QualificationResultComponent";
import { useCurrentSession } from "../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import PopoverButton from "../components/PopoverButton";
import LoadingBar from "../components/LoadingBar";

export default function QualificationResults() {
  const navigate = useNavigate();
  const { currentQualification, valueZones, usecaseQualificationQuestions } =
    useCurrentSession();
  const [responseTable, setResponseTable] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  /*
  React.useEffect(() => {
    if (currentQualification.qualification_responses) {
      setLoading(false);
    }
  }, [currentQualification]);
  */

  React.useEffect(() => {
    const createTable = (qualification, questions) => {
      let responses = [];
      Object.entries(qualification.qualification_responses).map((response) => {
        responses.push({
          question: questions.find((q) => q.name === response[0]).question,
          response: response[1].response,
          persona: questions.find((q) => q.name === response[0]).target_persona,
        });
      });
      return responses;
    };

    if (currentQualification.qualification_responses) {
      setResponseTable(
        createTable(currentQualification, usecaseQualificationQuestions)
      );
    }
    setLoading(false);
  }, [currentQualification, usecaseQualificationQuestions]);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
      {!loading ? (
        <React.Fragment>
          <Typography variant="h4" gutterBottom>
            Qualification Results for {currentQualification.details.account}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
            You've identified the following needs for the opportunity at{" "}
            {currentQualification.details.account}:
          </Typography>

          {currentQualification.usecases.map((usecase, i) => {
            return (
              <Grid container spacing={1} justifyContent="left" key={i}>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  sx={{ width: 60 }}
                >
                  <ChevronRightRounded
                    color="success"
                    fontSize="large"
                    sx={{ mt: -2 }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  maxWidth="md"
                >
                  <Typography paragraph textAlign="left" fontSize="1.2rem">
                    {valueZones
                      .find((vz) => vz.id === currentQualification.salesplay.id)
                      .usecases.filter((vz) => vz.id === usecase)
                      .map((vz) => vz.usecase)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Typography paragraph fontSize="1.2rem" gutterBottom>
            Based on the information you provided, the{" "}
            <span className="elevate">
              <b>{currentQualification.salesplay.name}</b>
            </span>{" "}
            Sales Play is the best fit for your opportunity with{" "}
            {currentQualification.details.account}:
          </Typography>

          <QualificationResultComponent
            submission={currentQualification}
            loading={loading}
            valueZone={valueZones}
            step="qualification"
          />
          <Typography
            variant="h6"
            justifyContent="left"
            align="left"
            fontSize="1.3rem"
            sx={{ fontWeight: 700, mt: 2, mb: 2 }}
            color="text.primary"
          >
            Qualification Responses
          </Typography>
          <Grid
            key="responses"
            container
            spacing={1}
            justifyContent="center"
            direction="column"
          >
            {!loading &&
              currentQualification.personas.map((persona) => (
                <Grid item>
                  <Accordion key={persona}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="splunk-pink" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container>
                        <Grid item width="100%">
                          <Typography
                            variant="h6"
                            justifyContent="left"
                            align="left"
                            fontSize="1.5rem"
                            sx={{ fontWeight: 700 }}
                            color="text.primary"
                          >
                            {persona}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="47.5%">
                                <Typography>Question</Typography>
                              </TableCell>
                              <TableCell width="5%" />
                              <TableCell width="47.5%">
                                <Typography>Response</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {responseTable
                              .filter((r) => r.persona === persona)
                              .map((response) => {
                                return (
                                  <TableRow key={response[0]}>
                                    <TableCell>
                                      <Typography>
                                        {response.question}
                                      </Typography>
                                    </TableCell>
                                    <TableCell />
                                    <TableCell>
                                      <Typography>
                                        {response.response}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="right"
            spacing={2}
          >
            <Grid item>
              <PopoverButton text={"Share"} />
            </Grid>
            <Grid item>
              <Button
                className="pink-button"
                variant="outlined"
                onClick={() => navigate("/profile")}
                sx={{
                  mt: 3,
                }}
              >
                Back to Profile
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="pink-button"
                variant="outlined"
                onClick={() => navigate("/discovery")}
                sx={{
                  mt: 3,
                }}
              >
                Continue to Discovery
              </Button>
            </Grid>
          </Grid>
          {!loading && console.log(responseTable)}
        </React.Fragment>
      ) : (
        <LoadingBar />
      )}
    </Container>
  );
}
