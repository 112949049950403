import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button, LinearProgress, Link, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useAuth } from "../contexts/AuthContext";
import { getQualSubmissions } from "../utils/firebaseFunctions";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CustomerAlert from "../components/CustomAlert";
import { useCurrentSession } from "../contexts/SessionContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));

export default function DiscoveryWelcome() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setCurrentDiscovery } = useCurrentSession();
  const [loading, setLoading] = React.useState(true);
  const [submissions, setSubmissions] = React.useState({});
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const { currentUser } = useAuth();

  const columns = [
    {
      field: "account",
      headerName: "Account",
      width: "300",
    },
    {
      field: "value_zone",
      headerName: "Value Zone",
      width: "200",
      isRowSelectable: true,
    },
    { field: "created_on", headerName: "Date Submitted", width: "150" },
    { field: "status", headerName: "Status", width: "150" },
  ];

  const handleSubmit = (selection) => {
    if (selection.length === 0) {
      setAlertOpen(true);
    } else {
      let submission = submissions.find(
        (s) => s.details.uid === selection.toString()
      );
      setCurrentDiscovery(submission);
      navigate("/discovery-planning");
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getQualSubmissions(currentUser.email);
        data.sort((a, b) =>
          a.details.created_on < b.details.created_on ? 1 : -1
        );
        setSubmissions(data);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };
    fetchData();
  }, [currentUser.email]);

  const handleSelectionModelChange = (newSelectionModel) => {
    newSelectionModel.some((r) => selectionModel.indexOf(r) >= 0)
      ? setSelectionModel([])
      : setSelectionModel(newSelectionModel);
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 20 }}>
      {!loading ? (
        <React.Fragment>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: 700,
            }}
          >
            Welcome to <span className="elevate">Splunk Discovery</span>
          </Typography>
          <Typography paragraph variant="h6" sx={{ mb: 3 }}>
            Welcome to Splunk Discovery, your guide to completing Observability
            and IT related discovery with your customers. This guide includes a
            set of critical questions needed to identify the most appropriate
            activity to execute during the opportunity. To use this guide you
            need to have completed the qualification step over at{" "}
            <Link href="/qualification-welcome">Splunk Curiosity</Link>.
          </Typography>
          <Typography paragraph variant="h6" sx={{ mb: 3 }}>
            To get started, select a qualification submission from the table
            below
          </Typography>
          <Box
            sx={{
              height: 400,
              width: "100%",
              ".MuiDataGrid-row.Mui-selected": {
                backgroundColor: "rgba(237, 0, 128, 0.25) !important",
                "&:hover": {
                  backgroundColor: "rgba(237, 0, 128, 0.35) !important",
                },
              },
              ".MuiDataGrid-row": {
                "&:hover": {
                  backgroundColor: "rgba(192,192,192 0.25) !important",
                },
              },
            }}
          >
            <DataGrid
              className={classes.root}
              rows={submissions.map((submission) => ({
                account: submission.details.account,
                created_on: new Date(
                  submission.details.created_on
                ).toDateString(),
                id: submission.details.uid,
                value_zone:
                  submission.qualification_status === "Complete"
                    ? submission.result.winzone[0].name
                    : "Incomplete",
                status: submission.qualification_status,
              }))}
              isRowSelectable={(params) => params.row.status === "Complete"}
              initialState={{
                sorting: {
                  sortModel: [{ field: "status", sort: "asc" }],
                },
              }}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableColumnMenu
              hideFooterSelectedRowCount
              onSelectionModelChange={handleSelectionModelChange}
              selectionModel={selectionModel}
            />

            <Button
              onClick={() => handleSubmit(selectionModel)}
              variant="outlined"
              sx={{
                float: "right",
                m: 3,
              }}
              className="pink-button"
            >
              Start Discovery
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
          <LinearProgress color="secondary" />
        </Stack>
      )}
      <CustomerAlert
        message="Please select a submission"
        severity="error"
        open={alertOpen}
        closeAlert={() => setAlertOpen(false)}
        margin={"10vh"}
      />
    </Container>
  );
}
