import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { ChevronRightRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ReactComponent as SplunkLogo } from "../static/images/splunk-logo-dark.svg";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{
            mb: 3,
          }}
        >
          <SplunkLogo
            fill="currentColor"
            alt="splunk>"
            style={{ verticalAlign: "middle" }}
          />
          <span
            className="elevate"
            style={{
              fontFamily: "monospace",
              fontWeight: 700,
            }}
          >
            SIMPl
          </span>{" "}
          is now{" "}
          <SplunkLogo
            fill="currentColor"
            alt="splunk>"
            style={{ verticalAlign: "middle" }}
          />
          <span className="elevate" style={{}}>
            <b>wtf</b>
          </span>
        </Typography>
        <Typography paragraph variant="h6" align="center" sx={{ mb: 4 }}>
          The following guides were created by the Observability Strategists and
          are intended to help qualify and provide important information about
          your IT and Observability opportunities.
        </Typography>

        <Grid container spacing={2}>
          <Grid
            item
            container
            direction="column"
            xs={12}
            sm={6}
            alignItems="center"
            justifyContent="center"
          >
            <Card elevation={1}>
              <CardContent>
                <Typography variant="h5">
                  <span className="splunk-pink">Step One:</span> Qualification
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  What Value Zone is right for this opportunity?
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                height="194"
                image="/static/images/PIA03276_orig.jpeg"
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    sm={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 80 }}
                  >
                    <ChevronRightRounded
                      color="success"
                      fontSize="large"
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    sm={10}
                    alignItems="center"
                    sx={{ height: 80 }}
                  >
                    <Typography paragraph>
                      Determine if an opportunity is qualified for the Splunk
                      Observability portfolio
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    sm={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 80 }}
                  >
                    <ChevronRightRounded
                      color="success"
                      fontSize="large"
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    sm={10}
                    alignItems="center"
                    sx={{ height: 80 }}
                  >
                    <Typography paragraph>
                      Identify which Value Zone is the best fit for your
                      opportunity
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    sm={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 80 }}
                  >
                    <ChevronRightRounded
                      color="success"
                      fontSize="large"
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    sm={10}
                    alignItems="center"
                    sx={{ height: 80 }}
                  >
                    <Typography paragraph>
                      Submit 'I Need Help' request to get further assistance
                    </Typography>
                  </Grid>
                </Grid>
                <Box textAlign="center">
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/qualification")}
                    className="pink-button"
                  >
                    Get Started with Qualification
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/*Card 2*/}

          <Grid
            item
            container
            direction="column"
            xs={12}
            sm={6}
            alignItems="center"
            justifyContent="center"
          >
            <Card elevation={1}>
              <CardContent>
                <Typography variant="h5">
                  <span className="splunk-pink">Step Two:</span> Discovery
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Guided discovery with opportunity specific recommendations
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                height="194"
                image="/static/images/Mercury_in_color.jpeg"
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    sm={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 80 }}
                  >
                    <ChevronRightRounded
                      color="success"
                      fontSize="large"
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    sm={10}
                    alignItems="center"
                    sx={{ height: 80 }}
                  >
                    <Typography paragraph>
                      Identify keys to a successful opportunity
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    sm={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 80 }}
                  >
                    <ChevronRightRounded
                      color="success"
                      fontSize="large"
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    sm={10}
                    alignItems="center"
                    sx={{ height: 80 }}
                  >
                    <Typography paragraph>
                      Details for providing the best possible demo
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    sm={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: 80 }}
                  >
                    <ChevronRightRounded
                      color="success"
                      fontSize="large"
                      sx={{ mt: -2 }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    sm={10}
                    alignItems="center"
                    sx={{ height: 80 }}
                  >
                    <Typography paragraph>
                      Recommendations for Hands-on Workshops and POC Planners
                    </Typography>
                  </Grid>
                </Grid>
                <Box textAlign="center">
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/discovery")}
                    className="pink-button"
                  >
                    Get Started with Discovery
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
