import React from "react";
import {
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import DashboardCard from "./DashboardCard";
import { getGlobalStatistics } from "../utils/firebaseFunctions";
import LoadingBar from "./LoadingBar";

ChartJS.register(ArcElement, Tooltip);

export default function Statistics() {
  const [loading, setLoading] = React.useState(true);
  const [statistics, setStatistics] = React.useState({});
  const [pieData, setPieData] = React.useState();
  const [fetchingData, setFetchingData] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getGlobalStatistics();
      //sort by data.usecases.[key].submissions
      data.usecases = Object.entries(data.usecases)
        .sort((a, b) => b[1].submissions - a[1].submissions)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      setStatistics(data);
      setFetchingData(false);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    function data() {
      let vzs = {};
      Object.entries(statistics.value_zones).map((vz) => {
        if (vz[1].submissions > 0) {
          return (vzs = { ...vzs, [vz[1].name]: vz[1].submissions });
        }
      });

      console.log(vzs);
      setPieData({
        labels: Object.keys(vzs),
        datasets: [
          {
            data: Object.values(vzs),
            backgroundColor: ["#ed0080", "#ca03fc", "#f16212"],
            borderWidth: 0,
          },
        ],
      });

      setLoading(false);
    }
    if (!fetchingData) {
      data();
    }
  }, [fetchingData]);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 20 }}>
      {!loading ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid item>
              <Typography variant="h6">Submission Details</Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <DashboardCard
                  title="Total Submissions"
                  value={statistics.total_submissions}
                />
              </Grid>
              <Grid item>
                <DashboardCard
                  title="Completed Discovery"
                  value={statistics.total_discovery_submissions}
                />
              </Grid>
              <Grid item>
                <Paper elevation={1} sx={{ width: 300, height: 200 }}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        textAlign="left"
                        variant="h6"
                        sx={{ ml: 2, fontSize: 22 }}
                        gutterBottom
                      >
                        Sales Plays
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item alignItems="center">
                      <Box
                        style={{
                          height: "140px",
                          width: "140px",
                          margin: "0 auto",
                          marginTop: "6px",
                          overflow: "show",
                        }}
                      >
                        <Pie
                          data={pieData}
                          options={{
                            maintainAspectRatio: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                enabled: false,
                                displayColors: false,
                                external: function (context) {
                                  // Tooltip Element
                                  let tooltipEl =
                                    document.getElementById("chartjs-tooltip");

                                  // Create element on first render
                                  if (!tooltipEl) {
                                    tooltipEl = document.createElement("div");
                                    tooltipEl.id = "chartjs-tooltip";
                                    tooltipEl.innerHTML = "<table></table>";
                                    document.body.appendChild(tooltipEl);
                                  }

                                  // Hide if no tooltip
                                  const tooltipModel = context.tooltip;
                                  if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                  }

                                  // Set caret Position
                                  tooltipEl.classList.remove(
                                    "above",
                                    "below",
                                    "no-transform"
                                  );
                                  if (tooltipModel.yAlign) {
                                    tooltipEl.classList.add(
                                      tooltipModel.yAlign
                                    );
                                  } else {
                                    tooltipEl.classList.add("no-transform");
                                  }

                                  function getBody(bodyItem) {
                                    return bodyItem.lines;
                                  }

                                  // Set Text
                                  if (tooltipModel.body) {
                                    const titleLines = tooltipModel.title || [];
                                    const bodyLines =
                                      tooltipModel.body.map(getBody);

                                    let innerHtml = "<thead>";

                                    titleLines.forEach(function (title, i) {
                                      innerHtml +=
                                        "<tr><th>" +
                                        title +
                                        ": " +
                                        bodyLines[i] +
                                        "</th></tr>";
                                    });
                                    innerHtml += "</thead><tbody>";
                                    innerHtml += "</tbody>";

                                    let tableRoot =
                                      tooltipEl.querySelector("table");
                                    tableRoot.innerHTML = innerHtml;
                                  }

                                  const position =
                                    context.chart.canvas.getBoundingClientRect();

                                  // Display, position, and set styles for font
                                  tooltipEl.style.opacity = 1;
                                  tooltipEl.style.position = "absolute";
                                  tooltipEl.style.left =
                                    position.left +
                                    window.pageXOffset +
                                    tooltipModel.caretX +
                                    "px";
                                  tooltipEl.style.top =
                                    position.top +
                                    window.pageYOffset +
                                    tooltipModel.caretY +
                                    "px";

                                  tooltipEl.style.padding =
                                    tooltipModel.padding +
                                    "px " +
                                    tooltipModel.padding +
                                    "px";
                                  tooltipEl.style.pointerEvents = "none";
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid item>
              <Grid item>
                <Typography variant="h6" sx={{ mt: 3 }}>
                  Submissions by Theater
                </Typography>
              </Grid>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <DashboardCard
                    cwidth={200}
                    cheight={200}
                    title="AMER"
                    value={statistics.theater.AMER.submissions}
                  />
                </Grid>
                <Grid item>
                  <DashboardCard
                    cwidth={200}
                    cheight={200}
                    title="EMEA"
                    value={statistics.theater.EMEA.submissions}
                  />
                </Grid>
                <Grid item>
                  <DashboardCard
                    cwidth={200}
                    cheight={200}
                    title="APAC"
                    value={statistics.theater.APAC.submissions}
                  />
                </Grid>
                <Grid item>
                  <DashboardCard
                    cwidth={200}
                    cheight={200}
                    title="PBST"
                    value={statistics.theater.PBST.submissions}
                  />
                </Grid>
                <Grid item>
                  <DashboardCard
                    cwidth={200}
                    cheight={200}
                    title="Other"
                    value={statistics.theater.Other.submissions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {/*
              <Grid item>
                <Typography variant="h6">Initiative Breakdown</Typography>
                <Table>
                  <TableBody>
                    {Object.entries(statistics.initiatives)
                      .filter((item) => item[1].description !== "undefinded")
                      .map((initiative) => (
                        <TableRow>
                          <TableCell>
                            {(initiative[1].responses.Yes /
                              statistics.total_submissions) *
                              100}
                            % of customers are {initiative[1].description}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
              */}
              <Grid item>
                <Typography variant="h6" sx={{ mt: 3 }}>
                  Top 5 usecases
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Usecase</TableCell>
                      <TableCell>Submissions</TableCell>
                      <TableCell>Submission %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(statistics.usecases)
                      .filter((item, idx) => idx < 5)
                      .map((usecase) => (
                        <TableRow key={usecase[1].id}>
                          <TableCell>{usecase[1].usecase}</TableCell>
                          <TableCell>{usecase[1].submissions}</TableCell>
                          <TableCell>
                            {usecase[1].submissions > 0
                              ? (
                                  (usecase[1].submissions /
                                    statistics.total_submissions) *
                                  100
                                ).toFixed(2)
                              : "0"}
                            %
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LoadingBar />
      )}
    </Container>
  );
}
