import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useAuth } from "../contexts/AuthContext";
import { useCurrentSession } from "../contexts/SessionContext";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import StyledCard from "../components/StyledCard";
import {
  getQualSubmissionByUID,
  updateSubmission,
} from "../utils/firebaseFunctions";
import { NavigateBefore, NavigateNext, ArrowRight } from "@mui/icons-material";
import LoadingBar from "../components/LoadingBar";

export default function QualificationInitiatives(props) {
  const defaultCompliance = {
    PCI: false,
    HIPAA: false,
    FedRAMP: false,
  };
  const {
    valueZones,
    winzoneQualifiers,
    currentQualification,
    setCurrentQualification,
  } = useCurrentSession();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  //const [valueZonesFS, setValueZonesFS] = React.useState({});
  //const [vz, setVz] = React.useState({});
  const [formValues, setFormValues] = React.useState({});
  const [usecases, setUsecases] = React.useState([]);
  const [personas, setPersonas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [compliance, setCompliance] = React.useState(defaultCompliance);

  const handleNext = (e) => {
    setFormValues({
      ...formValues,
      qualification_winzone_qualifier_8: e,
    });

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setFormValues({});
      setUsecases([]);
      setCompliance(defaultCompliance);
      setActiveStep(activeStep - 1);
    } else {
      props.onBack();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCompliance({
      ...compliance,
      [name]: checked,
    });
  };

  const handleUsecaseChange = (event) => {
    let newArray = [...usecases, event.target.value];
    if (usecases.includes(event.target.value)) {
      newArray = newArray.filter((usecase) => usecase !== event.target.value);
    }
    setUsecases(newArray);
  };

  const handlePersonaChange = (event) => {
    let newArray = [...personas, event.target.value];
    if (personas.includes(event.target.value)) {
      newArray = newArray.filter((persona) => persona !== event.target.value);
    }
    setPersonas(newArray);
  };

  const handleSubmit = (e) => {
    let submissionArray = [];
    e.preventDefault();
    setLoading(true);
    for (var key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        submissionArray.push({ name: key, values: formValues[key] });
      }
    }
    let updated_submission = {
      ...currentQualification,
      winzone_qualifiers: submissionArray,
      salesplay: {
        name: formValues["qualification_winzone_qualifier_8"],
        id: valueZones.filter(
          (vz) => vz.title === formValues["qualification_winzone_qualifier_8"]
        )[0].id,
      },
      usecases: usecases,
      personas: personas,
      qualification_status: "In Progress",
    };
    updateSubmission(currentQualification.docRef, updated_submission).then(
      (response) => {
        if (response.success) {
          getQualSubmissionByUID(response.success).then(
            (updated_submission) => {
              setCurrentQualification(updated_submission);
              navigate("/qualification-questions");
            }
          );
        } else {
          console.log("Error updating submission");
        }
      }
    );
  };

  /*
  React.useEffect(() => {
    setFormValues((formValues) => ({
      ...formValues,
      qualification_winzone_qualifier_7: compliance,
    }));
  }, [compliance]);
  */

  React.useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
      {!loading ? (
        <React.Fragment>
          <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
            Thanks, {currentUser.displayName}
          </Typography>
          <Typography sx={{ fontSize: "1.15rem", mb: 2 }}>
            {activeStep === 0
              ? `First, collect a bit of information about the environment and
            highest priorites at ${currentQualification.details.account}. Have a
            conversation with your customer and select which of the following
            most closely apply.`
              : "Next, select which usecases are most important to your customer."}
          </Typography>
          <Typography
            sx={{
              borderLeft: "5px solid rgba(237, 0, 128, .65)",
              fontSize: "1.5rem",
              textIndent: "1rem",
              mb: 2,
            }}
          >
            {activeStep === 0
              ? "Which of the following best describes your highest priority"
              : "Which usecases are the highest priority for your organization?"}
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            action="qualification-initiatives"
          >
            {!loading && activeStep === 0 ? (
              <Grid container justifyContent="center" spacing={3}>
                {valueZones
                  .filter((n) => n.attributes.include === true)
                  .map((n) => {
                    return (
                      <Grid item xs={12} sm={4} key={n.id}>
                        <StyledCard>
                          <CardActionArea
                            onClick={() => handleNext(n.title)}
                            sx={{ height: "100%" }}
                            key={n.id}
                            name={n.title}
                          >
                            <Box
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="h5"
                                  textAlign="center"
                                  gutterBottom
                                  className="splunk-pink"
                                >
                                  {n.title}
                                </Typography>
                                <Typography
                                  sx={{ mb: 1.5, height: 193 }}
                                  textAlign="justify"
                                  color="text.secondary"
                                >
                                  {n.headline}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  textAlign="center"
                                  sx={{ fontWeight: 600 }}
                                >
                                  Use cases
                                </Typography>

                                {n.usecases.map((usecases) => {
                                  return (
                                    <ListItem key={usecases.id}>
                                      <ListItemIcon>
                                        <ArrowRight />
                                      </ListItemIcon>

                                      <ListItemText
                                        sx={{ ml: -3 }}
                                        primary={
                                          <Typography textAlign="left">
                                            {usecases.usecase}
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  );
                                })}
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </StyledCard>
                      </Grid>
                    );
                  })}
              </Grid>
            ) : (
              <Box>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableBody>
                      {!loading &&
                        valueZones
                          .filter(
                            (n) =>
                              n.title ===
                              formValues.qualification_winzone_qualifier_8
                          )
                          .map((row) =>
                            row.usecases.map((usecase) => (
                              <TableRow
                                key={usecase.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell sx={{ width: "3%" }}>
                                  <FormControl>
                                    <FormControlLabel
                                      name={usecase.id}
                                      id={usecase.id}
                                      key={usecase.id}
                                      value={usecase.id}
                                      onChange={handleUsecaseChange}
                                      control={
                                        <Checkbox value={usecase.id}></Checkbox>
                                      }
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: "1.25rem",
                                      float: "flex-start",
                                    }}
                                  >
                                    {usecase.usecase}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography
                  sx={{
                    borderLeft: "5px solid rgba(237, 0, 128, .65)",
                    fontSize: "1.5rem",
                    textIndent: "1rem",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  Who will you be meeting with?
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableBody>
                      {!loading &&
                        valueZones
                          .filter(
                            (n) =>
                              n.title ===
                              formValues.qualification_winzone_qualifier_8
                          )
                          .map((row) =>
                            row.target_personas.map((persona) => (
                              <TableRow
                                key={persona}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell sx={{ width: "3%" }}>
                                  <FormControl>
                                    <FormControlLabel
                                      name={persona}
                                      id={persona}
                                      key={persona}
                                      value={persona}
                                      onChange={handlePersonaChange}
                                      control={
                                        <Checkbox value={persona}></Checkbox>
                                      }
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: "1.25rem",
                                      float: "flex-start",
                                    }}
                                  >
                                    {persona}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            {!loading && activeStep === 1 ? (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      onClick={handleBack}
                      variant="outlined"
                      className="pink-button"
                      sx={{ mt: 3 }}
                      startIcon={<NavigateBefore />}
                    >
                      Back to Select Initiatives
                    </Button>
                  </Grid>
                  <Grid item>
                    {
                      //if the user has selected at least one usecase and one persona, enable the next button
                      usecases.length > 0 && personas.length > 0 ? (
                        <Button
                          variant="outlined"
                          type="submit"
                          className="pink-button"
                          sx={{ mt: 3 }}
                          endIcon={<NavigateNext />}
                        >
                          Next
                        </Button>
                      ) : (
                        //if the user has not selected at least one usecase and one persona, disable the next button
                        <Button
                          variant="outlined"
                          type="submit"
                          className="pink-button"
                          sx={{ mt: 3 }}
                          endIcon={<NavigateNext />}
                          disabled
                        >
                          {usecases.length > 0 && personas.length === 0
                            ? "Select Personas"
                            : usecases.length === 0 && personas.length > 0
                            ? "Select Usecases"
                            : "Select Usecases and Personas"}
                        </Button>
                      )
                    }
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : null}
            <Grid container justifyContent="center" spacing={3}></Grid>
          </Box>
        </React.Fragment>
      ) : (
        <LoadingBar />
      )}
    </Container>
  );
}
