import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
  AccountBox,
  Assignment,
  BarChart,
  Explore,
  Logout,
  MyLocation,
} from "@mui/icons-material";
import { ReactComponent as SplunkLogo } from "../static/images/splunk-logo-dark.svg";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Grid, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { useCurrentSession } from "../contexts/SessionContext";
import LogoutAlert from "./LogoutAlert";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const settings = ["Switch Theme", "Logout"];
const menuItems = ["Qualification", "Discovery", "Statistics", "Profile"];

const getMenuIcons = (menuItem, themeIcon) => {
  const className = "splunk-pink";
  switch (menuItem) {
    case "Qualification":
      return <Explore className={className} />;
    case "Discovery":
      return <MyLocation className={className} />;
    case "Statistics":
      return <BarChart className={className} />;
    case "Profile":
      return <AccountBox className={className} />;
    case "Logout":
      return <Logout className={className} />;
    case "Switch Theme":
      return themeIcon === "dark" ? (
        <Brightness4Icon className={className} />
      ) : (
        <Brightness7Icon className={className} />
      );
    default:
      return <Assignment className={className} />;
  }
};

export default function NavbarWithDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { profile } = useCurrentSession();
  const [firstLogin, setFirstLogin] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [themeIcon, setThemeIcon] = React.useState(theme.palette.mode);
  const [loading, setLoading] = React.useState(true);
  const [auth, setAuth] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [logoutAlert, setLogoutAlert] = React.useState(false);
  const { currentUser, logout } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeTheme = () => {
    props.toggleTheme();
    setThemeIcon((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    var n = sessionStorage.length;
    while (n--) {
      var key = sessionStorage.key(n);
      if (key) {
        sessionStorage.removeItem(key);
      }
    }
    setAnchorElUser(null);
    setAuth(false);
    logout();
    return navigate("/login");
  };

  const handleCloseUserMenu = (event) => {
    const { value } = event.currentTarget.dataset;
    switch (value) {
      case "Logout":
        setAnchorElUser(null);
        return setLogoutAlert(true);
      case "Profile":
        setAnchorElUser(null);
        return navigate("/profile");
      case "Switch Theme":
        setAnchorElUser(null);
        return changeTheme();
      default:
        setAnchorElUser(null);
    }
  };

  const handleMenuClick = (event) => {
    const { value } = event.currentTarget.dataset;
    switch (value) {
      case "Profile":
        return navigate("/profile");
      case "Qualification":
        return navigate("/qualification");
      case "Discovery":
        return navigate("/discovery");
      case "Statistics":
        return navigate("/statistics");
      case "Logout":
        return setLogoutAlert(true);
      case "Switch Theme":
        return changeTheme();
      default:
        return navigate("/profile");
    }
  };

  React.useEffect(() => {
    if (currentUser && currentUser.email.endsWith("@splunk.com")) {
      setAuth(true);
    }
  }, [currentUser]);

  React.useEffect(() => {
    location.pathname === "/verify-login"
      ? setFirstLogin(true)
      : setFirstLogin(false);
  }, [location]);

  React.useEffect(() => {
    if (
      profile &&
      profile.displayMode !== undefined &&
      profile.displayMode !== theme.palette.mode
    ) {
      localStorage.setItem("theme", theme.palette.mode);
      changeTheme();
    }
    setLoading(false);
  }, [profile]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        style={{
          backgroundColor: themeIcon === "dark" ? "#111215" : "#FFFFFF",
        }}
      >
        <Toolbar>
          <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon className="splunk-pink" />
              </IconButton>
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <SplunkLogo
                onClick={() => navigate("/home")}
                fill={themeIcon === "dark" ? "#FFFFFF" : "#000000"}
                alt="splunk>"
                style={{ cursor: "pointer" }}
              />

              <Typography
                className="elevate"
                fontSize="2.2rem"
                noWrap
                component="a"
                onClick={() => navigate("/home")}
                sx={{
                  mb: "10px",
                  fontWeight: 700,
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                wtf
              </Typography>
            </Grid>
            <Grid item xs />
            {!loading && auth && (
              <Box sx={{ display: "flex-end" }}>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Menu>
                <Box sx={{ flexGrow: 0 }}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={currentUser.displayName}
                      src={currentUser.photoURL}
                    />
                  </IconButton>

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        data-value={setting}
                        onClick={handleCloseUserMenu}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: themeIcon === "dark" ? "#111215" : "#FFFFFF",
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className="splunk-pink" />
            ) : (
              <ChevronLeftIcon className="splunk-pink" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((menuItem) => (
            <ListItem key={menuItem} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={handleMenuClick}
                data-value={menuItem}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                disabled={firstLogin}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {getMenuIcons(menuItem)}
                </ListItemIcon>
                <ListItemText
                  primary={menuItem}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {settings.map((menuItem) => (
            <ListItem key={menuItem} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={handleMenuClick}
                data-value={menuItem}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                disabled={firstLogin}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {getMenuIcons(menuItem, themeIcon)}
                </ListItemIcon>
                <ListItemText
                  primary={menuItem}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} width={1}>
        <DrawerHeader />
        {!loading && <Outlet />}
      </Box>
      <LogoutAlert
        open={logoutAlert}
        handleClose={() => setLogoutAlert(false)}
        handleLogout={handleLogout}
      />
    </Box>
  );
}
