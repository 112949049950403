import * as React from "react";
import { AuthProvider } from "./contexts/AuthContext"
import { ThemeProvider } from "@mui/material/styles";
import './App.css';
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";

import Homepage from "./components/Homepage";
import FirebaseAuthPopup from "./components/FirebaseAuthPopup"
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import QualificationInitiatives from "./qualification/QualificationInitiatives";
import QualificationNeeds from "./qualification/QualificationNeeds";
import QualificationResults from "./qualification/QualificationResults";
import DiscoveryWelcome from "./discovery/DiscoveryWelcome";
import DiscoveryQuestions from "./discovery/DiscoveryQuestions";
import DiscoveryPlanning from "./discovery/DiscoveryPlanning";
import DiscoveryResults from "./discovery/DiscoveryResults";
import VerifyLogin from "./components/VerifyLogin";
import SessionHandler from "./components/SessionHandler";
import Profile from "./components/Profile";
import ProfileQualification from "./components/ProfileQualification";
import ProfileDiscovery from "./components/ProfileDiscovery";
import Statistics from "./components/Statistics";
import TechSummit from "./components/TechSummit";
import QualificationQuestions from "./qualification/QualificationQuestions";

const dark = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: "#111215",
    },
    primary: {
      main: '#ed0080',
    },    
    secondary: {
      main: '#ed0080',
    },
  },
  typography: {
    fontFamily: 'splunk_data_sans'
  }
});

const light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ed0080',
    },
    secondary: {
      main: '#ed0080',
    },
  },
  typography: {
    fontFamily: 'splunk_data_sans'
  }
});

function App() {
  const [theme, setTheme] = React.useState(dark);
  const [loading,setLoading] = React.useState(true);

  function toggleTheme() {
    setTheme((prevMode) => (prevMode === light ? dark : light));
  };

  function setThemeFromProfile(profileTheme) {
    profileTheme === "light" ? setTheme(light): setTheme(dark);
  }

  React.useEffect(() => {
    const setUserTheme = () => {
      !localStorage.getItem("theme") ? localStorage.setItem("theme", theme === light ? "light": "dark") : setTheme(localStorage.getItem("theme") === "light" ? light : dark)
    }
    setUserTheme();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    theme === light ? localStorage.setItem("theme", "light") : localStorage.setItem("theme", "dark")
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      {!loading && (
        <React.Fragment>
          <CssBaseline />
          <Router>
            <AuthProvider>
                <React.Fragment>
                  <Routes>
                    <Route exact path="/login" element={<FirebaseAuthPopup />} />
                    <Route path="/" element={<SessionHandler toggleTheme={toggleTheme}/>}>
                      <Route path="home" element={<Homepage />} />
                      <Route path="qualification" element={<ProfileQualification />} />
                      <Route path="qualification-initiatives" element={<QualificationInitiatives />} />
                      <Route path="qualification-questions" element={<QualificationQuestions />} />
                      <Route path="qualification-needs" element={<QualificationNeeds />} />
                      <Route path="qualification-results" element={<QualificationResults />} />
                      <Route path="discovery" element={<ProfileDiscovery />} />
                      <Route path="discovery-welcome" element={<DiscoveryWelcome />} />
                      <Route path="discovery-questions" element={<DiscoveryQuestions />} />
                      <Route path="discovery-planning" element={<DiscoveryPlanning />} />
                      <Route path="discovery-results" element={<DiscoveryResults />} />
                      <Route path="statistics" element={<Statistics />} />
                      <Route path="verify-login" element={<VerifyLogin setThemeFromProfile={setThemeFromProfile} toggleTheme={toggleTheme}/>} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="*" element={<Navigate to="/profile" />} />
                      <Route path="" element={<Navigate to="/profile" />} />
                    </Route>
                  </Routes>
                </React.Fragment>
            </AuthProvider>
          </Router>
      </React.Fragment>
      )}
    </ThemeProvider>

  );
}

export default App;
