import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  getQualSubmissionByUID,
  getQualSubmissions,
  writeSubmissionToFirebase,
} from "../utils/firebaseFunctions";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useCurrentSession } from "../contexts/SessionContext";
import { useAuth } from "../contexts/AuthContext";
import LoadingBar from "./LoadingBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));

export default function ProfileQualification(props) {
  const { currentUser } = useAuth();
  const [submissions, setSubmissions] = React.useState();
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const defaultValues = {
    account: "",
    sfdc: "",
  };
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const { setCurrentQualification, profile } = useCurrentSession();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let time = new Date().getTime();
    let submission = {
      details: {
        name: currentUser.displayName,
        email: currentUser.email,
        role: profile.role,
        theater: profile.theater,
        account: formValues.account,
        sfdc: formValues.sfdc,
        uid: "uid_" + time,
        created_on: time,
      },
      qualification_status: "Created",
    };
    writeSubmissionToFirebase(submission).then((response) => {
      if (response.success) {
        getQualSubmissionByUID(response.success).then((updated_submission) => {
          setCurrentQualification(updated_submission);
          navigate("/qualification-initiatives");
        });
      } else {
        setError(JSON.stringify(response));
        handleClickOpen();
      }
    });
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    newSelectionModel.some((r) => selectionModel.indexOf(r) >= 0)
      ? setSelectionModel([])
      : setSelectionModel(newSelectionModel);
  };

  const openQualification = (props) => {
    const { value } = props;
    const submission = submissions.find(
      (s) => s.details.uid === value.toString()
    );
    const onClick = (e) => {
      e.stopPropagation();
      switch (submission.qualification_status) {
        case "Created":
          setCurrentQualification(submission);
          navigate("/qualification-initiatives");
          break;
        case "In Progress":
          setCurrentQualification(submission);
          navigate("/qualification-questions");
          break;
        case "Complete":
          setCurrentQualification(submission);
          navigate("/qualification-results");
          break;
        default:
          break;
      }
    };
    if (value && submission.qualification_status !== "Complete") {
      return (
        <Button className="pink-button" variant="text" onClick={onClick}>
          Continue
        </Button>
      );
    } else {
      return (
        <Button className="pink-button" variant="text" onClick={onClick}>
          Open
        </Button>
      );
    }
  };

  openQualification.propTypes = {
    value: PropTypes.string,
  };

  const columns = [
    {
      field: "account",
      headerName: "Account",
      width: "300",
    },
    {
      field: "value_zone",
      headerName: "Value Zone",
      width: "200",
      isRowSelectable: false,
    },
    { field: "created_on", headerName: "Date Submitted", width: "150" },
    { field: "status", headerName: "Status", width: "150" },
    {
      field: "open",
      headerName: "",
      renderCell: openQualification,
      width: 200,
    },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      // Get qualification submissions
      try {
        const data = await getQualSubmissions();
        data.sort((a, b) =>
          a.details.created_on < b.details.created_on ? 1 : -1
        );
        setSubmissions(data);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    fetchData();
  }, [currentUser]);

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
      {!loading ? (
        <React.Fragment>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: 700,
            }}
          >
            Welcome to <span className="elevate">Splunk Curiosity</span>
          </Typography>
          <Typography paragraph variant="h6" sx={{ mb: 3 }}>
            Have you been working with your customer or prospect and everything
            you hear sounds like the perfect fit for the Splunk Observability
            portfolio? Want to make sure the opportunity is well qualified
            before you get too far into the sales cycle? Look no further! The
            following guide is intended to assist in identifying if an
            opportunity is a good fit for the Splunk Observability portfolio.
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            Provide the account details to get started
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            action="qualification-initiatives"
          >
            <Grid container direction="row" alignItems="center" spacing={3}>
              <Grid item width="40%">
                <TextField
                  fullWidth
                  id="account"
                  name="account"
                  label="Account"
                  variant="filled"
                  required
                  value={formValues.account}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item width="40%">
                <TextField
                  fullWidth
                  id="sfdc"
                  name="sfdc"
                  label="Link to Salesforce Opportunity (if available)"
                  variant="filled"
                  value={formValues.sfdc}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item width="20%">
                <Box fullWidth textAlign="right">
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#ed0080",
                      borderColor: "#ed0080",
                      "&:hover": {
                        borderColor: "#f16221",
                        color: "#f16221",
                      },
                    }}
                    type="submit"
                  >
                    Start New Qualification
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Typography variant="h6" display="block" gutterBottom>
              Previous Qualifications
            </Typography>
            {submissions.length === 0 ? (
              <Typography
                textAlign="center"
                display="block"
                sx={{ fontSize: "1.2rem" }}
                gutterBottom
              >
                It doesn't look like you've completed any qualifications. Get
                started using the form above.
              </Typography>
            ) : (
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  ".MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "rgba(237, 0, 128, 0.25) !important",
                    "&:hover": {
                      backgroundColor: "rgba(237, 0, 128, 0.35) !important",
                    },
                  },
                  ".MuiDataGrid-row": {
                    "&:hover": {
                      backgroundColor: "rgba(192,192,192 0.25) !important",
                    },
                  },
                  mt: 3,
                }}
              >
                <DataGrid
                  className={classes.root}
                  rows={submissions.map((submission) => ({
                    account: submission.details.account,
                    created_on: new Date(
                      submission.details.created_on
                    ).toDateString(),
                    id: submission.details.uid,
                    value_zone:
                      submission.qualification_status === "Complete"
                        ? submission.salesplay.name
                        : "Incomplete",
                    status: submission.qualification_status,
                    open: submission.details.uid,
                  }))}
                  isRowSelectable={(params) => params.row.status === "Complete"}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "status", sort: "desc" }],
                    },
                  }}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection={false}
                  disableColumnMenu
                  hideFooterSelectedRowCount
                  onSelectionModelChange={handleSelectionModelChange}
                  selectionModel={selectionModel}
                />
              </Box>
            )}
          </Box>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Whoops..."}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Something went wrong:
                  <br />
                  {error}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      ) : (
        <LoadingBar />
      )}
    </Container>
  );
}
