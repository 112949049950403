export function calculateQualificationResults(
  submission,
  qualification_questions,
  qualification_needs
) {
  console.log(submission.qualification_status);
  //if (submission.qualification_status !== "Complete") {
  /*
    var submission = getSubmissionFromFirebase_(uid);
    const qualification_questions = JSON.parse(
      sessionStorage.getItem("winzone_qualifiers")
    );
    const submission = sessionStorage.getItem("submission");
    const needs_object = JSON.parse(
      sessionStorage.getItem("qualification_needs")
    );
    */
  var needs_impact = [];
  var qualifiers_impact = [];
  var qualification_questions_array = [];

  submission.qualification_needs.map((r) => {
    //find r.name in needs_object
    var need = qualification_needs.find((n) => n.name === r.name);
    needs_impact.push(
      need.attributes.salesplay_impact,
      need.attributes.winzone_impact
    );
  });

  for (let i = 0; i < qualification_questions.length; i++) {
    let modifiers = {
      name: qualification_questions[i].name,
      response_modifiers:
        qualification_questions[i].attributes.modify_responses,
    };
    qualification_questions_array.push(modifiers);
  }

  submission.winzone_qualifiers.map((r) => {
    let response_modifiers = "";
    //find r.name in qualification_questions_array
    var qualifiers = qualification_questions_array.find(
      (n) => n.name === r.name
    );
    //if key equals FedRAMP, PCI, HIPAA, and value equals true, add 1 to impact
    if (r.name === "qualification_winzone_qualifier_7") {
      //for each key in r.values if value true add key to response_modifiers
      for (const [key, value] of Object.entries(r.values)) {
        if (value === true) {
          response_modifiers = qualifiers.response_modifiers.find((r) =>
            r.response.includes(key)
          );
        }
      }
    } else {
      response_modifiers = qualifiers.response_modifiers.find((n) =>
        n.response.includes(r.values)
      );
    }

    if (response_modifiers.modify_result === true) {
      for (let j = 0; j < response_modifiers.salesplay_impact.length; j++) {
        qualifiers_impact.push(response_modifiers.salesplay_impact[j]);
      }
      for (let j = 0; j < response_modifiers.winzone_impact.length; j++) {
        qualifiers_impact.push(response_modifiers.winzone_impact[j]);
      }
    }
  });

  var flat_needs_object = needs_impact.reduce((acc, curVal) => {
    return acc.concat(curVal);
  }, []);
  var reduced_qualifiers_impact = qualifiers_impact.reduce(
    (a, { id, impact }) => ((a[id] = (a[id] || 0) + impact), a),
    {}
  );
  var reduced_needs_impact = flat_needs_object.reduce(
    (a, { id, impact }) => ((a[id] = (a[id] || 0) + impact), a),
    {}
  );
  var calculated_total = Object.entries(reduced_needs_impact).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
    { ...reduced_qualifiers_impact }
  );

  var salesplays_total = [
    {
      id: "bsi",
      name: "Business Service Insights",
      amount: calculated_total.bsi,
    },
    {
      id: "itom",
      name: "IT Modernization (AIOps)",
      amount: calculated_total.itom,
    },
    { id: "itf", name: "IT Foundations", amount: calculated_total.itf },
    {
      id: "imt",
      name: "Infrastructure Monitoring and Troubleshooting",
      amount: calculated_total.imt,
    },
    {
      id: "o11y",
      name: "Observability Cloud",
      amount: calculated_total.o11y,
    },
    { id: "syn", name: "Synthetics", amount: calculated_total.syn },
  ];

  var winzone_total = [
    { id: "it", name: "IT Modernization", amount: calculated_total.it },
    { id: "cf", name: "Cloud Migration", amount: calculated_total.cf },
    {
      id: "dn",
      name: "Application Modernization",
      amount: calculated_total.dn,
    },
    {
      id: "logs",
      name: "Accelerate IT Troubleshooting",
      amount: calculated_total.logs,
    },
    {
      id: "itsi",
      name: "Predict & Resolve Incidents",
      amount: calculated_total.itsi,
    },
    {
      id: "oc",
      name: "Empower Unified Observability for Engineers",
      amount: calculated_total.oc,
    },
  ];
  var sorted_salesplays = salesplays_total.sort((a, b) =>
    a.amount < b.amount ? 1 : -1
  );
  var sorted_winzones = winzone_total.sort((a, b) =>
    a.amount < b.amount ? 1 : -1
  );

  let responses = getResponsesToQualifiers(submission, qualification_questions);

  var result = {
    salesplays: sorted_salesplays,
    winzone: sorted_winzones,
    responses: responses,
  };

  return result;
  /*
  } else {
    return submission.result;
  }
  */
}

function getResponsesToQualifiers(submission, winzone_questions) {
  const qualification_questions = submission.winzone_qualifiers;
  /*
  const winzone_questions = JSON.parse(
    sessionStorage.getItem("winzone_qualifiers")
  );
  */
  var qualifiers_array = [];
  for (let i = 0; i < qualification_questions.length; i++) {
    if (
      qualification_questions[i].values.PCI === true ||
      qualification_questions[i].values.HIPAA === true ||
      qualification_questions[i].values.FedRAMP === true ||
      (qualification_questions[i].name ===
        "qualification_winzone_qualifier_6" &&
        qualification_questions[i].values === "No")
    ) {
      qualifiers_array.push(qualification_questions[i].name);
    }
  }

  //var responses_object = qualifiers_array.map((r) => {
  //  winzone_questions.find((n) => n.name === r);
  //});
  var responses_object = [];
  for (let i = 0; i < qualifiers_array.length; i++) {
    responses_object.push(
      winzone_questions.find((n) => n.name === qualifiers_array[i])
    );
  }

  var responses = [];
  for (let i = 0; i < responses_object.length; i++) {
    responses.push(responses_object[i].response);
  }
  return responses;
}
