import React, { useContext, useState, useEffect } from "react";
import { auth, signInWithGoogle } from "../firebase";
import { signOut } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login() {
    return signInWithGoogle();
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    console.log("rendered auth provider");
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const value = {
    currentUser,
    loading,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
