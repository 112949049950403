// Import FirebaseAuth and firebase.
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Container, Grid, Typography } from "@mui/material";
import { Paper } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { ReactComponent as SplunkLogo } from "../static/images/splunk-logo-dark.svg";
import backgroundImage from "../static/images/background-image.svg";
import Divider from "@mui/material/Divider";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/verify-login",
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

function SignInScreen() {
  return (
    <Container
      maxWidth={false}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#111215",
        width: "100*",
        height: "100vh",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <Grid item>
          <Paper
            elevation={1}
            sx={{
              backgroundColor: "#222",
              width: 700,
              height: 400,
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Grid item>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  sx={{
                    mb: 3,
                  }}
                  color="#FFFFFF"
                >
                  <SplunkLogo
                    fill="#FFFFFF"
                    alt="splunk>"
                    style={{ verticalAlign: "middle" }}
                  />
                  <span
                    className="elevate"
                    style={{
                      fontFamily: "monospace",
                      fontWeight: 700,
                    }}
                  >
                    SIMPl
                  </span>{" "}
                  is now{" "}
                  <SplunkLogo
                    fill="#FFFFFF"
                    alt="splunk>"
                    style={{ verticalAlign: "middle" }}
                  />
                  <span className="elevate" style={{}}>
                    <b>wtf</b>
                  </span>
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                height="200px"
              >
                <Grid item width="275px">
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      mb: 0,
                    }}
                    color="#FFFFFF"
                  >
                    Click Sign in with Google to get started
                  </Typography>
                </Grid>

                <Divider
                  orientation="vertical"
                  sx={{ bgcolor: "#777" }}
                  flexItem
                />

                <Grid item width="275px">
                  <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebase.auth()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignInScreen;
