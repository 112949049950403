import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import {
  updateSubmission,
  getQualSubmissionByUID,
} from "../utils/firebaseFunctions";
import DiscoveryStepper from "../components/DiscoveryStepper";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  LinearProgress,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCurrentSession } from "../contexts/SessionContext";

const defaultDiscoveryCategories = [
  "Overview",
  "Monitoring",
  "APM",
  "Synthetic",
  "Sizing",
];

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function DiscoveryQuestions() {
  const navigate = useNavigate();
  const { currentDiscovery, setCurrentDiscovery, discoveryQuestions } =
    useCurrentSession();
  const [activeStep, setActiveStep] = React.useState(0);
  const [discoveryCategories, setDiscoveryCategories] = React.useState(
    defaultDiscoveryCategories
  );
  const [questions, setQuestions] = React.useState([]);
  const [formValues, setFormValues] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const setSelectedCategories = () => {
    let selectedQuestions = [];
    let defaultFormValues = {};
    for (var key in currentDiscovery.selected_discovery_questions) {
      if (currentDiscovery.selected_discovery_questions[key].length > 0) {
        selectedQuestions.push({
          id: key,
          name: defaultDiscoveryCategories.find((s) => s.toLowerCase() === key),
          question_keys: currentDiscovery.selected_discovery_questions[key],
        });
        currentDiscovery.selected_discovery_questions[key].forEach(
          (question) => {
            defaultFormValues[question] = { selection: "", notes: "" };
          }
        );
      }
    }
    setQuestions(selectedQuestions);
    setFormValues(defaultFormValues);
    setDiscoveryCategories(
      selectedQuestions
        .map((question) => question.name)
        .sort(
          (a, b) =>
            defaultDiscoveryCategories.indexOf(a) -
            defaultDiscoveryCategories.indexOf(b)
        )
    );
  };

  const isChecked = (questionName, opts) => {
    if (formValues[questionName]["selection"][opts]) {
      return true;
    } else if (formValues[questionName]["selection"][opts] === undefined) {
      return false;
    } else {
      return false;
    }
  };

  const onFinish = () => {
    updateSubmission(currentDiscovery.docRef, {
      discovery_responses: formValues,
      discovery_status: "Complete",
    }).then((response) => {
      if (response.success) {
        getQualSubmissionByUID(response.success).then((updated_submission) => {
          setCurrentDiscovery(updated_submission);
          navigate("/discovery-results");
        });
      } else {
        console.log("Error updating submission");
      }
    });
  };

  const questionType = (row) => {
    switch (row.question_type) {
      case "radio":
        return (
          <React.Fragment>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              name={row.name}
              value={formValues[row.name]["selection"]}
            >
              {row.questions_options.map((opts) => (
                <FormControlLabel
                  key={row.name + "_" + opts}
                  value={opts}
                  onChange={handleInputChange}
                  control={
                    <Radio
                      size="small"
                      required={true}
                      sx={{
                        "&.Mui-checked": {
                          color: "#ed0080",
                        },
                      }}
                    />
                  }
                  label={opts}
                />
              ))}
            </RadioGroup>
          </React.Fragment>
        );
      case "checkbox":
        return (
          <React.Fragment>
            <FormGroup aria-label="checkboxes-group-label">
              {row.questions_options.map((opts) => (
                <FormControlLabel
                  key={row.name + "_" + opts}
                  value={opts}
                  onChange={handleInputChange}
                  control={
                    <Checkbox
                      checked={isChecked(row.name, opts)}
                      size="small"
                      name={row.name}
                      sx={{
                        "&.Mui-checked": {
                          color: "#ed0080",
                        },
                      }}
                    />
                  }
                  label={opts}
                />
              ))}
            </FormGroup>
          </React.Fragment>
        );
      case "number":
        return (
          <React.Fragment>
            <FormGroup aria-label="sizing-group-label">
              <TextField
                label="Amount"
                variant="outlined"
                type="number"
                name={row.name}
                value={formValues[row.name]["selection"]}
                onChange={handleInputChange}
              />
            </FormGroup>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    setSelectedCategories();
    setLoading(false);
  }, []);

  const handleInputChange = (e) => {
    const { name, type, value, checked } = e.target;
    console.log(name, type, value, checked);
    if (type === "radio" || type === "number") {
      setFormValues((values) => {
        return {
          ...values,
          [name]: {
            selection: value,
            notes: values[name]["notes"],
          },
        };
      });
    } else if (type === "checkbox") {
      setFormValues((values) => {
        return {
          ...values,
          [name]: {
            selection: { ...values[name]["selection"], [value]: checked },
            notes: values[name]["notes"],
          },
        };
      });
    } else if (type === "text") {
      console.log(name);
      setFormValues((values) => {
        return {
          ...values,
          [name]: {
            selection: values[name]["selection"],
            notes: value,
          },
        };
      });
    }
  };

  return (
    <React.Fragment>
      {!loading ? (
        <Container maxWidth="xl" sx={{ mb: 2 }}>
          <DiscoveryStepper
            steps={discoveryCategories}
            activeStep={setActiveStep}
            onFinish={() => onFinish()}
          >
            <Paper sx={{ width: "100%", mb: 2 }}>
              {!loading ? (
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="small"
                  >
                    <TableBody>
                      {discoveryQuestions
                        .filter(
                          (question) =>
                            question.category ===
                              discoveryCategories[activeStep].toLowerCase() &&
                            question.attributes.include === true &&
                            questions
                              .filter(
                                (q) =>
                                  q.name === discoveryCategories[activeStep]
                              )
                              .map((n) => n.question_keys)
                              .flat()
                              .includes(question.name) === true
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              key={row.name}
                              hover
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell width="40%" id={labelId} scope="row">
                                <Typography>{row.question}</Typography>
                              </TableCell>

                              <TableCell width="5%" align="center">
                                <CustomWidthTooltip
                                  title={
                                    <Typography align="center">
                                      {row.tooltip}
                                    </Typography>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <Icon sx={{ width: "40px", height: "40px" }}>
                                    <HelpOutline
                                      sx={{
                                        verticalAlign: "middle",
                                        color: "#ed0080",
                                        fontSize: "2rem",
                                      }}
                                    />
                                  </Icon>
                                </CustomWidthTooltip>
                              </TableCell>

                              <TableCell width="20%">
                                <FormControl sx={{ float: "left" }}>
                                  {questionType(row)}
                                </FormControl>
                              </TableCell>
                              <TableCell width="35%">
                                <FormControl fullWidth sx={{ float: "left" }}>
                                  <FormGroup aria-label="sizing-group-label">
                                    <TextField
                                      name={row.name}
                                      width="100%"
                                      label="Additional Notes"
                                      variant="outlined"
                                      type="standard"
                                      value={formValues[row.name]["notes"]}
                                      onChange={handleInputChange}
                                    />
                                  </FormGroup>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton>
                  <TableContainer />
                </Skeleton>
              )}
            </Paper>
          </DiscoveryStepper>
        </Container>
      ) : (
        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
          <LinearProgress color="secondary" />
        </Stack>
      )}
    </React.Fragment>
  );
}
