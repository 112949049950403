import * as React from "react";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  checkProfile,
  updateProfileAfterLogin,
} from "../utils/firebaseFunctions";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useCurrentSession } from "../contexts/SessionContext";

export default function VerifyLogin(props) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [profileUpdate, setProfileUpdate] = React.useState(false);
  const { profile, setProfile } = useCurrentSession();
  const defaultValues = {
    displayName: currentUser.displayName,
    role: "",
    theater: "",
    displayMode: "dark",
  };
  const [formValues, setFormValues] = React.useState(defaultValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //props.setThemeFromProfile(formValues.displayMode);
    updateProfileAfterLogin(formValues).then((response) => {
      if (response.success) {
        checkProfile(currentUser).then((profileDetails) => {
          setLoading(true);
          setProfileUpdate(true);
          setProfile(profileDetails);
          navigate("/profile");
        });
      }
    });
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        try {
          const profileDetails = await checkProfile(currentUser);
          setProfile(profileDetails);
        } catch (e) {
          console.log(e);
        }
      }
    };
    fetchProfile();
  }, [currentUser]);

  useEffect(() => {
    console.log(profile);
    if (profile && profile.firstLogin === false && profileUpdate === false) {
      navigate("/profile");
    } else if (profile && profile.firstLogin === true) {
      setLoading(false);
    }
  }, [profile]);

  return (
    <React.Fragment>
      {!loading && (
        <Container component="main" maxWidth="lg" sx={{ mb: 2 }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: 700,
            }}
          >
            Welcome, {profile.displayName}
          </Typography>

          <Typography paragraph variant="h6" sx={{ mb: 3 }}>
            Let's get started by creating your profile.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            action="qualification-initiatives"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Grid item container direction="column" xs={12} sm={6}>
                <TextField
                  id="display-name"
                  name="displayName"
                  label="Name"
                  variant="filled"
                  required={true}
                  value={formValues.displayName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item container direction="column" xs={12} sm={6}>
                <FormControl variant="filled">
                  <InputLabel>Role</InputLabel>
                  <Select
                    id="role"
                    name="role"
                    label="Role"
                    required
                    value={formValues.role}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="" disabled>
                      Chose Your Role
                    </MenuItem>
                    <MenuItem value="Inside Sales">
                      Inside Sales Reresentative
                    </MenuItem>
                    <MenuItem value="Regional Sales Manager">
                      Regional Sales Manager
                    </MenuItem>
                    <MenuItem value="Regional Sales Director">
                      Regional Sales Director
                    </MenuItem>
                    <MenuItem value="Sales Engineer">Sales Engineer</MenuItem>
                    <MenuItem value="Solution Architect">
                      Solution Architect
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container direction="column" xs={12} sm={6}>
                <FormControl variant="filled">
                  <InputLabel>Theater/Region</InputLabel>
                  <Select
                    id="theater"
                    name="theater"
                    label="Theater/Region"
                    required
                    value={formValues.theater}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="" disabled>
                      Chose Your Theater/Region
                    </MenuItem>
                    <MenuItem value="AMER">AMER</MenuItem>
                    <MenuItem value="EMEA">EMEA</MenuItem>
                    <MenuItem value="APAC">APAC</MenuItem>
                    <MenuItem value="PBST">PBST</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container direction="column" xs={12} sm={6}>
                <FormControl variant="filled">
                  <InputLabel>Display Mode</InputLabel>
                  <Select
                    id="display-mode"
                    name="displayMode"
                    label="Display Mode"
                    required
                    value={formValues.displayMode}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="dark">Dark</MenuItem>
                    <MenuItem value="light">Light</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box textAlign="right" mt={4}>
              <Button
                variant="outlined"
                sx={{
                  color: "#ed0080",
                  borderColor: "#ed0080",
                  "&:hover": {
                    borderColor: "#f16221",
                    color: "#f16221",
                  },
                }}
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
}
