import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  updateSubmission,
  getQualSubmissionByUID,
} from "../utils/firebaseFunctions";
import DiscoveryStepper from "../components/DiscoveryStepper";
import {
  Box,
  Container,
  FormControl,
  FormGroup,
  Icon,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { ArrowRight, HelpOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCurrentSession } from "../contexts/SessionContext";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function QualificationQuestions() {
  const navigate = useNavigate();
  const {
    currentQualification,
    usecaseQualificationQuestions,
    setCurrentQualification,
  } = useCurrentSession();
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const onFinish = () => {
    const updated_submission = {
      ...currentQualification,
      qualification_responses: formValues,
      qualification_status: "Complete",
      result: { responses: [] },
    };
    updateSubmission(currentQualification.docRef, updated_submission).then(
      (response) => {
        if (response.success) {
          getQualSubmissionByUID(response.success).then(
            (updated_submission) => {
              setCurrentQualification(updated_submission);
              navigate("/qualification-results");
            }
          );
        } else {
          console.log("Error updating submission");
        }
      }
    );
  };

  React.useEffect(() => {
    setLoading(false);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((values) => {
      return {
        ...values,
        [name]: {
          response: value,
        },
      };
    });
  };

  return (
    <React.Fragment>
      {!loading ? (
        <Container maxWidth="xl" sx={{ mb: 2 }}>
          <DiscoveryStepper
            steps={currentQualification.personas}
            activeStep={setActiveStep}
            onFinish={() => onFinish()}
          >
            <Paper sx={{ width: "100%", mb: 2 }}>
              {!loading ? (
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="small"
                  >
                    <TableBody>
                      {usecaseQualificationQuestions
                        .filter(
                          (question) =>
                            question.target_persona ===
                              currentQualification.personas[activeStep] &&
                            question.category ===
                              currentQualification.salesplay.id &&
                            question.attributes.include === true
                        )
                        .sort(
                          (a, b) =>
                            a.name.match(/\d+$/g) - b.name.match(/\d+$/g)
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              key={row.name}
                              hover
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell width="42.5%" id={labelId} scope="row">
                                <Typography>{row.question}</Typography>
                              </TableCell>

                              <TableCell width="5%" align="center">
                                <CustomWidthTooltip
                                  title={
                                    <Box>
                                      <Typography
                                        align="justify"
                                        fontWeight="700"
                                        gutterBottom
                                      >
                                        {row.tooltip_title}
                                      </Typography>
                                      {row.tooltip_type === "text" ? (
                                        <Typography>{row.tooltip}</Typography>
                                      ) : (
                                        row.tooltip.map((tooltip) => (
                                          <ListItem key={row.name}>
                                            <ListItemIcon>
                                              <ArrowRight />
                                            </ListItemIcon>

                                            <ListItemText
                                              sx={{ ml: -3 }}
                                              primary={
                                                <Typography textAlign="left">
                                                  {tooltip}
                                                </Typography>
                                              }
                                            />
                                          </ListItem>
                                        ))
                                      )}
                                    </Box>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <Icon sx={{ width: "40px", height: "40px" }}>
                                    <HelpOutline
                                      sx={{
                                        verticalAlign: "middle",
                                        color: "#ed0080",
                                        fontSize: "2rem",
                                      }}
                                    />
                                  </Icon>
                                </CustomWidthTooltip>
                              </TableCell>
                              <TableCell width="42.5%">
                                <FormControl fullWidth sx={{ float: "left" }}>
                                  <FormGroup aria-label="sizing-group-label">
                                    <TextField
                                      name={row.name}
                                      width="100%"
                                      label="Response"
                                      variant="outlined"
                                      type="standard"
                                      onChange={handleInputChange}
                                    />
                                  </FormGroup>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Skeleton>
                  <TableContainer />
                </Skeleton>
              )}
            </Paper>
          </DiscoveryStepper>
        </Container>
      ) : (
        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
          <LinearProgress color="secondary" />
        </Stack>
      )}
    </React.Fragment>
  );
}
