import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function CustomAlert(props) {
  const message = props.message;
  const severity = props.severity;
  const open = props.open;
  const margin = props.margin;

  console.log("Alert Open:", open);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.closeAlert();
  };

  return (
    <React.Fragment>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ mb: margin }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
