import * as React from "react";
import AuthenticatedPage from "./AuthenticatedPage";
import { SessionProvider } from "../contexts/SessionContext";
import NavbarWithDrawer from "./NavbarWithDrawer";

export default function SessionHandler(props) {
  React.useEffect(() => {
    console.log("rendered Session Handler", new Date().getTime());
  }, []);
  return (
    <AuthenticatedPage>
      <SessionProvider>
        <NavbarWithDrawer toggleTheme={props.toggleTheme} />
      </SessionProvider>
    </AuthenticatedPage>
  );
}
