import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, Link } from "react-router-dom";
import { Button, LinearProgress, Stack } from "@mui/material";
import { useCurrentSession } from "../contexts/SessionContext";
import { getQualSubmissions } from "../utils/firebaseFunctions";
import { useAuth } from "../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));

export default function DiscoveryWelcome(props) {
  const classes = useStyles();
  const { currentUser } = useAuth;
  const navigate = useNavigate();
  const [submissions, setSubmissions] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const { setCurrentDiscovery } = useCurrentSession();

  const openQualification = (props) => {
    const { value } = props;
    const submission = submissions.find(
      (s) => s.details.uid === value.toString()
    );
    const onClick = (e) => {
      e.stopPropagation();
      console.log("Discovery Status:", submission.discovery_status);
      switch (submission.discovery_status) {
        case undefined:
          setCurrentDiscovery(submission);
          navigate("/discovery-planning");
          break;
        case "Questions Selected":
          setCurrentDiscovery(submission);
          navigate("/discovery-questions");
          break;
        case "Complete":
          setCurrentDiscovery(submission);
          navigate("/discovery-results");
          break;
        default:
          break;
      }
    };
    if (value && submission.discovery_status === "Questions Selected") {
      return (
        <Button className="pink-button" variant="text" onClick={onClick}>
          Continue Discovery
        </Button>
      );
    } else if (value && submission.discovery_status === "Complete") {
      return (
        <Button className="pink-button" variant="text" onClick={onClick}>
          Open Discovery
        </Button>
      );
    } else {
      return (
        <Button className="pink-button" variant="text" onClick={onClick}>
          Start Discovery
        </Button>
      );
    }
  };

  openQualification.propTypes = {
    value: PropTypes.string,
  };

  const columns = [
    {
      field: "account",
      headerName: "Account",
      width: "300",
    },
    {
      field: "value_zone",
      headerName: "Value Zone",
      width: "200",
      isRowSelectable: true,
    },
    { field: "created_on", headerName: "Date Submitted", width: "150" },
    { field: "status", headerName: "Discovery Status", width: "175" },
    {
      field: "open",
      headerName: "",
      renderCell: openQualification,
      width: 200,
    },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      // Get qualification submissions
      try {
        const data = await getQualSubmissions();
        data.sort((a, b) =>
          a.details.created_on < b.details.created_on ? 1 : -1
        );
        setSubmissions(data);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    fetchData();
  }, [currentUser]);

  const handleSelectionModelChange = (newSelectionModel) => {
    newSelectionModel.some((r) => selectionModel.indexOf(r) >= 0)
      ? setSelectionModel([])
      : setSelectionModel(newSelectionModel);
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 20 }}>
      {!loading ? (
        <React.Fragment>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: 700,
            }}
          >
            Welcome to <span className="elevate">Splunk Discovery</span>
          </Typography>
          <Typography paragraph variant="h6" sx={{ mb: 3 }}>
            Welcome to Splunk Discovery, your guide to completing Observability
            and IT related discovery with your customers. This guide includes a
            set of critical questions needed to identify the most appropriate
            activity to execute during the opportunity.
          </Typography>
          {submissions.filter(
            (submission) => submission.qualification_status === "Complete"
          ).length === 0 ? (
            <Typography
              textAlign="center"
              display="block"
              sx={{ fontSize: "1.2rem" }}
              gutterBottom
            >
              It doesn't look like you've completed any qualifications. Get
              started <Link to="/qualification">here</Link>
            </Typography>
          ) : (
            <React.Fragment>
              <Typography paragraph variant="h6" sx={{ mb: 3 }}>
                To get started, select a qualification submission from the table
                below
              </Typography>
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  ".MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "rgba(237, 0, 128, 0.25) !important",
                    "&:hover": {
                      backgroundColor: "rgba(237, 0, 128, 0.35) !important",
                    },
                  },
                  ".MuiDataGrid-row": {
                    "&:hover": {
                      backgroundColor: "rgba(192,192,192 0.25) !important",
                    },
                  },
                }}
              >
                <DataGrid
                  className={classes.root}
                  rows={submissions
                    .filter((s) => s.qualification_status === "Complete")
                    .map((submission) => ({
                      account: submission.details.account,
                      created_on: new Date(
                        submission.details.created_on
                      ).toDateString(),
                      id: submission.details.uid,
                      value_zone:
                        submission.qualification_status === "Complete"
                          ? submission.salesplay.name
                          : "Incomplete",
                      status: submission.discovery_status
                        ? submission.discovery_status
                        : "Not Started",
                      open: submission.details.uid,
                    }))}
                  isRowSelectable={(params) => params.row.status === "Complete"}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "status", sort: "asc" }],
                    },
                  }}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection={false}
                  disableColumnMenu
                  hideFooterSelectedRowCount
                  onSelectionModelChange={handleSelectionModelChange}
                  selectionModel={selectionModel}
                />
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
          <LinearProgress color="secondary" />
        </Stack>
      )}
    </Container>
  );
}
